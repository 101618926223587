import React, { useEffect, useState } from "react";
import "./TransactionsAvalider.scss";
import Select from "react-select";
import DataTable from "react-data-table-component";
import {
  Alert,
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import IconPlusYellow from "../../../assets/images/svg/puls-icon-yellow.svg";

import { BootyPagination } from "../../../components/table/pagination";
import { data } from "../../../constants";
import { BsCheck2Circle, BsTrashFill } from "react-icons/bs";
import {
  Company,
  ICategory,
  IFacture,
  IPermission,
  IS3,
  ISubCategory,
  ITransaction,
  User,
} from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../config";
import axios from "axios";
import moment from "moment";
import {
  setCounter,
  setErrorMessage,
} from "../../../store/reducers/utils/utilSlice";
import {
  RiArchiveFill,
  RiArrowDropDownLine,
  RiCloseCircleFill,
  RiCloseLine,
  RiDeleteBin5Line,
  RiDownload2Fill,
  RiEdit2Fill,
  RiErrorWarningLine,
  RiSaveFill,
  RiSearchLine,
  RiTimeFill,
} from "react-icons/ri";
import tvaEditIcon from "../../../assets/images/svg/edit-small-blue.svg";
import TourVeh from "../../../assets/images/vahTypeTour.png";
import UtilVeh from "../../../assets/images/vehTypeUtil.png";
import { useFormatter } from "../../../util/hooks/useFormatter";
import Encaissements from "../Encaissements";
import Decaissements from "../Decaissements";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import FileViewer from "../../../components/FileViewer";
import { number } from "yup/lib/locale";
import ArchiveIcon from "../../../assets/ArchiveIcon";
import {
  AppPacks,
  ASSETS_OPTIONS,
  ASSETS_OPTIONS_VALIDATORS,
  CarbKeys,
  CarbVehTypes,
  CrudPermissions,
  IndemCategKeys,
  InvoiceTransactionParentTypes,
  InvoiceTransactionTypes,
  NonImmoPacks,
  OptionType,
  PayDiffKeys,
  TransactionMediums,
  TVAS_OPTIONS,
  UserTypes,
  VEHICULE_TYPES,
} from "../../../util/context";
import MoneySacIconBlue from "../../../assets/images/svg/money-sac-blue.svg";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import MoneySacIconGreen from "../../../assets/images/svg/money-sac-green.svg";
import CalenderIconGreen from "../../../assets/images/svg/calender-icon-green.svg";
import { useNavigate } from "react-router-dom";
import SaveIconWhite from "../../../assets/SaveIconWhite";
import { ReactSVG } from "react-svg";
import EditIcon from "../../../assets/EditIcon";
import { useTVA } from "../../../util/hooks/useTVA";
import { FaEye, FaFileInvoice, FaTrash } from "react-icons/fa";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { usePermissions } from "../../../util/hooks/usePermissions";
import {
  ImmobStateHandler,
  PayDiffStateHandler,
} from "../../../components/Tabs/Tabs";
import useAxios from "../../../util/hooks/useAxios";
import {
  deleteFile,
  deleteFileFromList,
} from "../../ClientInfo/InformationClient";
import AttacheIcon from "../../../assets/AttacheIcon";
import { FiInfo } from "react-icons/fi";
import ViewIcon from "../../../assets/ViewIcon";
import { cellComp } from "../../Immobilisation/ImmobilisationCurrent/ImmobilisationCurrent";
import { toast, ToastContainer } from "react-toastify";
import FilePicker from "../../../components/FilePicker";
import { IoMdCreate } from "react-icons/io";
import ZoomIcon from "../../../assets/ZoomIcon";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

export interface AvaliderProps {}

const { API_URL } = config[process.env.NODE_ENV];

export type SearchFormValues = {
  name: string | null;
};

type DynamicObj = {
  [prop: string]: [value: boolean];
};

type ImmobFormValues = {
  type: OptionType | null;
  numberOfYears: number | null;
  transactionName: string | null;
  transactionDate: string | null;
  transactionAmount: number | null;
};

type EmpruntFormValues = {
  capitalAmount: number | null;
  chargeAmount: number | null;
  insuranceAmount: number | null;
};

export type GeneralFormValues = {
  amount: number | null;
  label: string | null;
  date: string | null;
  category: { value: string; label: string } | null;
  carbVehType: { value: string; label: string } | null;
  vat: string | null;
  documents?: any;
  modalType?: string | null;
  relatedToFactureId?: string | null;
  relatedToFactureType?: string | null;
  code?: string | null;
  sub_category_id?: string | null;
  facture_ids: string[] | null;
  sign?: string | null;
};

export type TVAFormValues = {
  id: number | string;
  tva_amount: number | null;
  tva: { value: number; label: string } | null;
};

const TransactionsAvalider = ({
  context,
  callback,
  reload,
  company,
  immobStateHandler,
  payDiffStateHandler,
  transactionNameSearch,
  setTransactionNameSearch,
}: {
  context?: string;
  callback?: any;
  reload?: boolean;
  company?: Company;
  immobStateHandler?: ImmobStateHandler;
  payDiffStateHandler?: PayDiffStateHandler;
  transactionNameSearch?: string;
  setTransactionNameSearch?: any;
}) => {
  const [loading, setLoading] = useState(false);
  const [carbLoading, setCarbLoading] = useState(false);
  const [addNoteFraisModal, setAddNoteFraisModal] = useState<boolean>(false);
  const [addEncModal, setAddEncModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [immbModal, setImmobModal] = useState<boolean>(false);
  const [immobMoreInfo, setImmobMoreInfo] = useState<boolean>(false);
  const [payDiffValidation, setPayDiffValidation] = useState<boolean>(false);
  const [payDiffArchive, setPayDiffArchive] = useState<boolean>(false);
  const [openCategModal, setOpenCategModal] = useState<boolean>(false);
  const [stopArchiveModal, setStopArchiveModal] = useState<boolean>(false);
  const [empruntModal, setEmpruntModal] = useState<boolean>(false);
  const [tvaModal, setTVAModal] = useState<boolean>(false);
  const [tvaUpdateLoading, setTvaUpdateLoading] = useState<boolean>(false);
  const [mixteTVASection, setMixteTVASection] = useState<boolean>(false);
  const [singleTVAAmount, setSingleTVAAmount] = useState<OptionType | null>(
    null
  );
  const [firstRowId, setFirstRowId] = useState(0);
  const [updatingFirst, setUpdatingFirst] = useState(false);
  const [addNewRow, setAddNewRow] = useState<boolean>(false);
  const [rows, setRows] = useState<TVAFormValues[]>([]);
  const [empruntMoreInfo, setempruntMoreInfo] = useState<boolean>(false);
  const [singleEmprunt, setSingleEmprunt] = useState<ITransaction | null>();
  const [categStatus, setCategStatus] = useState<string | null>();
  const [categRemb, setCategremb] = useState<boolean>(false);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [editTVAClick, setEditTVAClick] = useState<DynamicObj>({});
  const [editDateClick, setEditDateClick] = useState<DynamicObj>({});
  const [payDiffEditTVAClick, setPayDiffEditTVAClick] = useState<DynamicObj>(
    {}
  );
  const [payDiffNeddValidation, setPayDiffNeddValidation] =
    useState<DynamicObj>({});
  const [url, setUrl] = useState<string | null>(null);
  const [decCategs, setDecCategs] = useState<ICategory[]>([]);
  const [encCategs, setEncCategs] = useState<ICategory[]>([]);
  const [isImmobCateg, setIsImmobCateg] = useState<ISubCategory | null>();

  const [noFilesModal, setNoFilesModal] = useState<boolean>(false);
  const [noFilesLoading, setNoFilesLoading] = useState<boolean>(false);
  const [payDiffCreateLoading, setPayDiffCreateLoading] =
    useState<boolean>(false);
  const [noFilesTrans, setNoFilesTrans] = useState<ITransaction | null>();
  const [singleTrans, setSingleTrans] = useState<ITransaction | null>();
  const [payDiffTrans, setPayDiffTrans] = useState<ITransaction | null>();
  const [payDiffSubCateg, setPayDiffSubCateg] = useState<ISubCategory | null>();
  const [singleImmob, setImmob] = useState<ITransaction | null>();
  const [singleCarb, setSingleCarb] = useState<ITransaction | null>();
  const [singleTVA, setSingleTVA] = useState<{
    id: string;
    value: string | null;
  }>({} as { id: string; value: string | null });
  const [singleDate, setSingleDate] = useState<{
    id: string;
    value: string | null | Date;
  } | null>();
  const [singlePayDiffTVA, setSinglePayDiffTVA] = useState<{
    id: string;
    value: string | null;
  } | null>();
  const [file, setFile] = useState("");
  const [isImmobModal, setIsImmobModal] = useState<boolean>(false);
  const [isCarbModal, setIsCarbModal] = useState<boolean>(false);
  const [diffPayModal, setDiffPayModal] = useState<boolean>(false);
  const [diffPayValidateModal, setDiffPayValidateModal] =
    useState<boolean>(false);
  const [diffPayArchiveModal, setDiffPayArchiveModal] =
    useState<boolean>(false);
  const [closeCategSearch, setCloseCategSearch] = useState<boolean>(false);
  const [searchCategName, setSearchCategName] = useState<string | null>();
  const [refreshCategs, setRefreshCategs] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string | null>();
  const [documentsModal, setDocumentsModal] = useState<boolean>(false);
  const [viewDocumentsTransaction, setViewDocumentsTransaction] =
    useState<ITransaction | null>(null);

  const [billSuggestModal, setBillSuggestModal] = useState<boolean>(false);
  const [billAdd, setBillAdd] = useState<{
    id: string;
    isLoading: boolean;
  } | null>(null);
  const [billDelete, setBillDelete] = useState<{
    key: string;
    isLoading: boolean;
  } | null>(null);
  const [billAddCustom, setBillAddCustom] = useState<boolean>(false);
  const [billSuggestions, setBillSuggestions] = useState<IFacture[]>([]);
  const [tempBillSuggestions, setTempBillSuggestions] = useState<IFacture[]>(
    []
  );
  const [billTransaction, setBillTransaction] = useState<ITransaction | null>();
  const { setDecimalDigits } = useFormatter();
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const ontoggle = (id: string) => {
    setTransactionId(id);
    setOpen(true);
  };

  const [pending, setPending] = useState<boolean>(true);

  const [totalRows, setTotalRows] = useState(0);
  const [totalBillRows, setTotalBillRows] = useState(0);
  const [factureNameSearch, setFactureNameSearch] = useState("");

  const [transactionsToValid, setTransactionsToValid] = useState<
    ITransaction[]
  >([]);

  const [documents, setDocuments] = useState<any>([]);

  const [payDiffTransArray, setPAyDiffTransArray] = useState<ITransaction[]>(
    []
  );

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user: connectedUser, permissions } = useSelector(
    (state: { user: object; permissions: object }) => state.user
  ) as { user: User; permissions: IPermission[] };

  const { hasCrudPermission } = usePermissions();
  let api = useAxios();

  const deleteTransactionDocument = async (
    row: ITransaction,
    document: IS3
  ) => {
    try {
      const options: any = {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };
      toast.dismiss();

      toast.warning(
        "Cela peut prendre quelques secondes, veuillez patienter...",
        options
      );
      setBillDelete({
        key: document.key,
        isLoading: true,
      });

      let transactions: any = [];

      const deleteCheck = await deleteFileFromList(
        [document.key],
        "documents",
        "Transaction",
        row.id,
        creds.token,
        api
      );

      if (row.relatedToFactureType === "child" && row.factures) {
        const index = row.factures.findIndex((facture) => {
          const factureFile = facture.billS3link.key;
          if (
            document.key.toLowerCase().includes("dette") ||
            document.key.toLowerCase().includes("encaissement")
          ) {
            const fileToRemove = document.key.toLowerCase().replaceAll("-", "");
            const factureFileUpdated = factureFile
              .toLowerCase()
              .replaceAll("-", "");
            return (
              fileToRemove.substring(
                fileToRemove.length - factureFileUpdated.length,
                fileToRemove.length + 1
              ) === factureFileUpdated
            );
          }
          if (document.key.toLowerCase().includes("fa_")) {
            const factureIdStartIndex = document.key
              .toLowerCase()
              .indexOf("fa_");
            const fileToRemove = document.key.toLowerCase().includes("-invoice")
              ? document.key
                  .toLowerCase()
                  .substring(
                    factureIdStartIndex,
                    document.key.toLowerCase().indexOf("-invoice")
                  )
              : document.key
                  .toLowerCase()
                  .substring(
                    factureIdStartIndex,
                    document.key.toLowerCase().indexOf("invoice")
                  );
            return factureFile.toLowerCase().includes(fileToRemove);
          }
        });
        if (index > -1) {
          await detatchTransactionFromInvoice(row, index);
        }
      }
      if (
        company &&
        company.pack &&
        NonImmoPacks.includes(company?.pack! as any)
      ) {
        await checkTransactionsBills(context!);
      }

      transactions = await getTransactions(
        transactionNameSearch && transactionNameSearch !== ""
          ? transactionNameSearch
          : "",
        true
      );

      if (payDiffTrans) {
        transactions = await getPayDiffTransactions(payDiffTrans?.id!);
      }

      if (viewDocumentsTransaction && viewDocumentsTransaction.id) {
        const transactionIndex = transactions.findIndex(
          (tr: ITransaction) => tr.id === viewDocumentsTransaction.id
        );
        if (transactionIndex > -1) {
          setViewDocumentsTransaction(transactions[transactionIndex]);
        }
      }
      setBillDelete(null);
    } catch (error: any) {
      ErrorLogger("deleting a transaction's document", error);
      setBillDelete(null);
    }
  };

  const columns = React.useMemo(
    () => [
      //here we are referencing the transaction with it's very own code
      // if it was created manually and it is >0 then CA
      // if it was created manually and it is <0 then NDF
      // if it was created with bridge and associated to an iban and it is the iban code
      {
        name: "",
        selector: (row: any) => row.iban,
        cell: (row: ITransaction) => (
          <span className="code-cell">
            {row.iban && row.iban.code && <span>{row.iban.code}</span>}
            {row.type && row.type === "cash" && <span>CA</span>}
            {row.type && row.type === "expense" && <span>NDF</span>}
          </span>
        ),
        id: "code-cell-wrapper",
      },
      {
        name: "Date",
        selector: (row: any) => row.date,
        sortable: true,
        cell: (row: ITransaction) => (
          <span className="tr-date-cell-wrapper">
            <span className="tr-date-cell">
              {!editDateClick[row.id] ? (
                moment(row.date).format("DD/MM/YYYY")
              ) : (
                <DatePicker
                  onChange={(date: any) =>
                    setSingleDate((prevState: any) => {
                      const returnObj = {
                        ...prevState,
                        value: date,
                      };
                      return returnObj;
                    })
                  }
                  selected={
                    singleDate?.value ? new Date(singleDate?.value) : null
                  }
                  className="form-control date-update-form"
                  locale="fr"
                  dateFormat="dd/MM/yyyy"
                />
              )}
            </span>
            <span className="tr-edit-date-cell">
              {!editDateClick[row.id] ? (
                <RiEdit2Fill
                  onClick={() => {
                    setSingleDate({
                      id: row.id,
                      value: row.date,
                    });
                    setEditDateClick((prevState: any) => {
                      let old = { ...prevState };
                      for (let item of Object.keys(old)) {
                        if (old[item] === true && item !== row.id) {
                          old[item] = false;
                        }
                      }
                      return {
                        ...old,
                        [row.id]: !prevState[row.id],
                      };
                    });
                  }}
                />
              ) : (
                <button
                  className="btn btn-blue"
                  onClick={async () => await editTransactionDate(row)}
                >
                  <SaveIconWhite />
                </button>
              )}
            </span>
          </span>
        ),
      },
      // if transaction has category it will be shown and it can be updated based on transaction sign pos or neg
      {
        name: "Transaction",
        selector: (row: any) => row.label,
        sortable: true,
        cell: (row: any) => (
          <div className="tr-categ-cell-wrapper">
            <p>{row.label}</p>
            <div className="tr-categ-cell">
              {!row.sub_category && (
                <p
                  onClick={() => {
                    if (parseFloat(row.amount) > 0) {
                      setCategStatus("enc");
                    } else {
                      setCategStatus("dec");
                    }
                    setSingleTrans(row);
                    setOpenCategModal(true);
                  }}
                  className="tr-categ-cell-toCateg"
                >
                  À catégoriser <RiArrowDropDownLine size={30} />
                </p>
              )}
              {row.sub_category && (
                <p>
                  {row.sub_category.name}{" "}
                  {row.relatedToFactureId &&
                  row.relatedToFactureType === "child" &&
                  row.code &&
                  row.code !== "" ? (
                    <></>
                  ) : (
                    <RiEdit2Fill
                      onClick={() => {
                        if (parseFloat(row.amount) > 0) {
                          setCategStatus("enc");
                        } else {
                          setCategStatus("dec");
                        }
                        setSingleTrans(row);
                        setOpenCategModal(true);
                      }}
                    />
                  )}
                </p>
              )}
            </div>
          </div>
        ),
      },
      {
        name: "Justificatifs",
        selector: (row: any) => row.justif,
        sortable: true,
        cell: (row: ITransaction) => {
          return (
            <div className="file-table">
              <FormGroup className="form-file file-primary">
                {billSuggestions.length > 0 ? (
                  <label
                    className="file-box "
                    onClick={async () => {
                      try {
                        setBillTransaction(row);
                        setBillSuggestModal(true);
                      } catch (error: any) {
                        ErrorLogger("updating a transaction's document", error);
                      }
                    }}
                  >
                    <RiDownload2Fill />
                    <span className="form-default">Télécharger d'ici</span>
                  </label>
                ) : (
                  <label className="file-box ">
                    <RiDownload2Fill />
                    <Input
                      type="file"
                      className="form-default"
                      onChange={async (e) => {
                        const options: any = {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        };
                        try {
                          toast.dismiss();

                          toast.warning(
                            "Cela peut prendre quelques secondes, veuillez patienter...",
                            options
                          );
                          const formData = new FormData();
                          formData.append("id", row?.id);
                          if (e.target.files && e.target.files.length > 0) {
                            for (
                              let index = 0;
                              index < e.target.files.length;
                              index++
                            ) {
                              const element = e.target.files[index];
                              formData.append(
                                "documents",
                                element,
                                element.name
                              );
                            }
                          }

                          const { data } = await api.post(
                            `/api/Transaction/Update`,
                            formData,
                            {
                              headers: {
                                "x-access-token": creds.token,
                              },
                            }
                          );

                          toast.success(
                            "Votre mise à jour a été effectuée avec succès test",
                            options
                          );
                          await getTransactions(
                            transactionNameSearch &&
                              transactionNameSearch !== ""
                              ? transactionNameSearch
                              : "",
                            true
                          );
                        } catch (error: any) {
                          ErrorLogger(
                            "updating a transaction's document",
                            error
                          );
                          toast.error(
                            "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
                            options
                          );
                        }
                      }}
                      multiple
                    />
                    <span> Télécharger d'ici</span>
                  </label>
                )}
              </FormGroup>
              <div className="list-Files">
                {row.documents && row.documents.length > 0 && (
                  <>
                    {row.documents.length === 1 &&
                      row.documents.map((document, index) => (
                        <span
                          className="file-box-item transaction-file"
                          key={index}
                        >
                          <FaEye
                            onClick={() => {
                              setUrl(document.url);
                              setViewModal(true);
                            }}
                          />
                          <span>{document.key}</span>
                          <FaTrash
                            onClick={async () => {
                              await deleteTransactionDocument(row, document);
                            }}
                          />
                        </span>
                      ))}
                    {row.documents.length > 1 && (
                      <span
                        className="file-box-item justif-viewer"
                        onClick={() => {
                          setViewDocumentsTransaction(row);
                          setDocumentsModal(true);
                        }}
                      >
                        <FaEye size={20} /> Voir tous
                        {row.factures && row.factures.length > 0 && (
                          <>
                            {" "}
                            <FaFileInvoice size={15}></FaFileInvoice>
                          </>
                        )}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        },
      },
      {
        name: "Montant",
        sortable: true,
        cell: (row: any) => (
          <span
            className={parseFloat(row.amount) > 0 ? "green-num" : "red-num"}
          >
            {setDecimalDigits(row.amount)} €
          </span>
        ),
      },
      // omit is hiding columns fr a certain condition
      {
        name: "Tva",
        sortable: true,
        omit: !company?.subjectToVAT,
        cell: (row: ITransaction) => {
          // getting the trasnaction's tva percentage ,if it has one or identifying it throu the associated category's tva percentage
          let vatPercentage =
            row.vatPercentage !== null && row.vatPercentage !== undefined
              ? `${row.vatPercentage} %`
              : row.sub_category &&
                row.sub_category.TVA &&
                !isNaN(parseFloat(row.sub_category.TVA)) &&
                parseFloat(row.sub_category.TVA) !== 0
              ? `${parseFloat(row.sub_category.TVA)} %`
              : (null as any);

          // if the transaction is a splitted one,it means a trasnaction's amount is splitted on multiple amounts with each amount has it's own tva
          if (row.splittedTransactions && row.splittedTransactions.length > 0) {
            vatPercentage = "Mixte";
          }
          return (
            <>
              <div className="tva-box">
                <div className="tva-box-input  i-secondary">
                  <input
                    key={row.id}
                    name="tva"
                    placeholder="0"
                    className="num-tva"
                    value={row.vat ? parseFloat(row.vat).toFixed(2) : ""}
                    type="number"
                    min={0}
                    disabled
                  />
                  <span className="device-tva">€ TVA</span>
                  <button
                    className="icon-tva"
                    type="submit"
                    disabled={
                      (row.sub_category &&
                        row.sub_category.key &&
                        [
                          PayDiffKeys.IN26,
                          PayDiffKeys.IN27,
                          PayDiffKeys.OUT100,
                          PayDiffKeys.OUT101,
                        ].includes(
                          row.sub_category.key as any
                        )) as unknown as boolean
                    }
                    onClick={() => {
                      setSingleTrans(row);
                      if (
                        row.splittedTransactions &&
                        row.splittedTransactions.length > 0
                      ) {
                        setSingleTVAAmount({ label: "Mixte ", value: "mixte" });
                        setMixteTVASection(true);
                        let _rows = row.splittedTransactions.map((elt) => ({
                          tva_amount: elt.amount,
                          id: elt.id,
                          tva: {
                            value: elt.vatAmount,
                            label: TVAS_OPTIONS.find(
                              (data) => data.value === elt.vatAmount
                            )?.label,
                          },
                        }));
                        setRows(_rows as unknown as TVAFormValues[]);
                      } else {
                        let sub_category_tva_perc =
                          row.vatPercentage !== null &&
                          row.vatPercentage !== undefined
                            ? row.vatPercentage
                            : row["sub_category"] &&
                              row["sub_category"]["TVA"] &&
                              !isNaN(parseFloat(row["sub_category"]["TVA"]))
                            ? parseFloat(
                                parseFloat(row["sub_category"]["TVA"]).toFixed(
                                  1
                                )
                              )
                            : null;

                        if (
                          sub_category_tva_perc !== null &&
                          sub_category_tva_perc !== undefined
                        ) {
                          let val = TVAS_OPTIONS.find(
                            (data) => data.value === sub_category_tva_perc
                          );
                          if (val) {
                            setSingleTVAAmount(val);
                          }
                        }
                      }
                      setTVAModal(true);
                    }}
                  >
                    {<ReactSVG src={tvaEditIcon} />}
                    {/* <img src={tvaEditIcon} alt="icon" /> */}
                  </button>
                </div>

                {vatPercentage && (
                  <span className="mixte-ind">Taux : {vatPercentage}</span>
                )}
              </div>
            </>
          );
        },
      },
      {
        name: "Statut",
        selector: (row: any) => row.status,
        cell: (row: ITransaction) => (
          <>
            <Button
              color={row.status === 100 ? "warning" : "success"}
              className="btn-status btn-small"
              onClick={async () => {
                try {
                  if (row.sub_category_id) {
                    if (
                      row.sub_category.name === "Emprunt" &&
                      row.amount <= 0 &&
                      row.status === 100
                    ) {
                      setSingleEmprunt(row);
                      setEmpruntModal(true);
                      return;
                    }

                    if (
                      [
                        PayDiffKeys.IN26,
                        PayDiffKeys.IN27,
                        PayDiffKeys.OUT100,
                        PayDiffKeys.OUT101,
                      ].includes(row.sub_category.key as any) &&
                      row.status === 100
                    ) {
                      await diffPayCheckValidate(row);
                      return;
                    }

                    const parentCateg = decCategs.find((elt) => {
                      return elt.sub_categories?.find(
                        (elt) => elt.id === row.sub_category_id
                      );
                    });

                    if (
                      parentCateg?.name.includes("Immobilisations") &&
                      !row.numberOfYears
                    ) {
                      setImmob(row);
                      immobReset({
                        transactionAmount: row.amount,
                        transactionDate: moment(row.date).format("YYYY-MM-DD"),
                        type: {
                          value: row.sub_category.id,
                          label: `${row.sub_category.name} ${
                            row.sub_category.yearsRange.length > 1
                              ? `(${row.sub_category.yearsRange[0]} à ${row.sub_category.yearsRange[1]} ans)`
                              : `(${row.sub_category.yearsRange[0]} ans)`
                          }`,
                        },
                      });
                      setImmobModal(true);
                      return;
                    }
                  }
                  await validateTransaction(row);
                } catch (error: any) {
                  ErrorLogger("updating a transaction's document", error);
                }
              }}
            >
              {row.status === 100
                ? company?.pack === AppPacks.Entrepreneur
                  ? "À comptabiliser"
                  : "À valider"
                : company?.pack === AppPacks.Entrepreneur
                ? "Comptabiliser"
                : "Validé"}
            </Button>
          </>
        ),
      },
      {
        name: creds.role === UserTypes.Client ? "Archiver" : "Action",
        button: true,
        cell: (row: ITransaction) => (
          <>
            {row.status === 100 ? (
              <>
                {permissions.length > 0 &&
                hasCrudPermission(
                  permissions,
                  CrudPermissions.DeleteTransactions
                ) ? (
                  <button
                    className="btn btn-red"
                    onClick={() => ontoggle(row.id)}
                  >
                    <BsTrashFill />
                  </button>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <button
                className="btn btn-orange"
                onClick={async () => {
                  if (!row.subCategoryId) {
                    setStopArchiveModal(true);
                    return;
                  }
                  // here we are checking if the transaction
                  // is a pay diff / enc diff trasnaction
                  // to see if we should archive the pay diff / enc diff transactions
                  // that are associated to it first
                  if (
                    [
                      PayDiffKeys.IN26,
                      PayDiffKeys.IN27,
                      PayDiffKeys.OUT100,
                      PayDiffKeys.OUT101,
                    ].includes(row.sub_category.key as any)
                  ) {
                    await diffPayCheckArchive(row);
                    return;
                  }
                  if (
                    (row.documents && row.documents.length > 0) ||
                    row.sub_category.key === IndemCategKeys.OUT102
                  ) {
                    await archiveTransaction(row.id);
                  } else {
                    setNoFilesTrans(row);
                    setNoFilesModal(true);
                    return;
                  }
                }}
              >
                <ArchiveIcon />
              </button>
            )}
          </>
        ),
      },
    ],
    [
      company,
      permissions,
      editTVAClick,
      singleTVA,
      editDateClick,
      singleDate,
      billSuggestions,
    ]
  );

  const billColumns = [
    {
      name: cellComp("Nº facture"),
      selector: (row: any) => String(row.id).toUpperCase(),
      sortable: true,
      format: (row: any) => (
        <span title={String(row.id).toUpperCase()}>
          {String(row.id).toUpperCase()}
        </span>
      ),
    },
    {
      name: "Société",
      selector: (row: any) =>
        // i know it's stupid,don't judge...
        row.clientInfo &&
        row.clientInfo.clientName &&
        row.clientInfo.clientName !== ""
          ? row.clientInfo.clientName
          : "",
      sortable: true,
    },
    {
      name: "Total HT",
      selector: (row: any) => `${setDecimalDigits(row.totalHT)} €`,
      sortable: true,
    },
    {
      name: "Total TTC",
      selector: (row: any) => `${setDecimalDigits(row.totalTTC)} €`,
      sortable: true,
    },
    {
      name: "Date de création",
      selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (row: IFacture) => (
        <div className="table-action">
          {row.billS3link && row.billS3link.key && (
            <>
              <button
                className="btn btn-blue"
                onClick={() => {
                  setUrl(row.billS3link.url);
                  setViewModal(true);
                }}
                disabled={
                  billAdd! && billAdd?.id === row.id && billAdd?.isLoading
                }
              >
                <ViewIcon />
              </button>
              <button
                className="btn btn-green"
                onClick={async () => {
                  if (payDiffTrans && payDiffTrans.id) {
                    // const canAdd = checkCanAddInvoice(
                    //   row,
                    //   getValues("amount")! || billTransaction?.amount || 0
                    // );
                    // if (!canAdd) {
                    //   toast.dismiss();
                    //   toast.warning(
                    //     `Le montant de la facture choisie dépassera le montant restant de cette transaction.`,
                    //     {
                    //       position: "bottom-right",
                    //       autoClose: 3000,
                    //       hideProgressBar: false,
                    //       closeOnClick: true,
                    //       pauseOnHover: true,
                    //       draggable: true,
                    //       progress: undefined,
                    //     }
                    //   );
                    //   return;
                    // }
                    await addBillToPayDiffTransaction(
                      row,
                      billTransaction || null
                    );
                  } else {
                    // const canAdd = checkCanAddInvoice(
                    //   row,
                    //   billTransaction?.amount!,
                    //   billTransaction?.factures || []
                    // );
                    // console.log("yes yes : ", billTransaction);
                    // if (!canAdd) {
                    //   toast.dismiss();
                    //   toast.warning(
                    //     `Le montant de la facture choisie dépassera le montant restant de cette transaction.`,
                    //     {
                    //       position: "bottom-right",
                    //       autoClose: 3000,
                    //       hideProgressBar: false,
                    //       closeOnClick: true,
                    //       pauseOnHover: true,
                    //       draggable: true,
                    //       progress: undefined,
                    //     }
                    //   );
                    //   return;
                    // }
                    await addBillToTransaction(billTransaction!, row);
                  }
                }}
                disabled={
                  billAdd! && billAdd?.id === row.id && billAdd?.isLoading
                }
              >
                {billAdd && billAdd.id === row.id && billAdd.isLoading ? (
                  <Spinner color="success" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  <BsCheck2Circle />
                )}
              </button>
            </>
          )}
        </div>
      ),
    },
  ];

  // these are the columns that appear once you create a pay diff / enc diff transaction
  // or update a transaction's categ to pay diff / enc diff
  const diffPayColumns = React.useMemo(
    () => [
      {
        name: "Date",
        selector: (row: any) => row.date,
        sortable: true,
        cell: (row: { date: Date }) => moment(row.date).format("DD/MM/YYYY"),
      },
      {
        name: "Transaction",
        selector: (row: any) => row.label,
        sortable: true,
        cell: (row: any) => (
          <div className="tr-categ-cell-wrapper">
            <p>{row.label}</p>
            <div className="tr-categ-cell">
              {!row.sub_category && (
                <p
                  onClick={() => {
                    if (parseFloat(row.amount) > 0) {
                      setCategStatus("enc");
                    } else {
                      setCategStatus("dec");
                    }
                    setSingleTrans(row);
                    setOpenCategModal(true);
                  }}
                  className="tr-categ-cell-toCateg"
                >
                  À catégoriser <RiArrowDropDownLine size={30} />
                </p>
              )}

              {row.sub_category && (
                <p>
                  {row.sub_category.name}{" "}
                  <RiEdit2Fill
                    onClick={() => {
                      if (parseFloat(row.amount) > 0) {
                        setCategStatus("enc");
                      } else {
                        setCategStatus("dec");
                      }
                      setSingleTrans(row);
                      setOpenCategModal(true);
                    }}
                  />
                </p>
              )}
            </div>
          </div>
        ),
      },
      {
        name: "Justificatifs",
        selector: (row: any) => row.justif,
        sortable: true,
        cell: (row: ITransaction) => (
          <div className="file-table">
            <FormGroup className="form-file file-primary">
              <label className="file-box ">
                <RiDownload2Fill />
                {billSuggestions.length > 0 ? (
                  <span
                    className="form-default"
                    onClick={async () => {
                      try {
                        setBillTransaction(row);
                        setBillSuggestModal(true);
                      } catch (error: any) {
                        ErrorLogger("updating a transaction's document", error);
                      }
                    }}
                  >
                    Télécharger d'ici
                  </span>
                ) : (
                  <>
                    <Input
                      type="file"
                      className="form-default"
                      onChange={async (e) => {
                        const options: any = {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        };
                        try {
                          toast.dismiss();

                          toast.warning(
                            "Cela peut prendre quelques secondes, veuillez patienter...",
                            options
                          );
                          const formData = new FormData();
                          formData.append("id", row?.id);
                          if (e.target.files && e.target.files.length > 0) {
                            for (
                              let index = 0;
                              index < e.target.files.length;
                              index++
                            ) {
                              const element = e.target.files[index];
                              formData.append(
                                "documents",
                                element,
                                element.name
                              );
                            }
                          }

                          await api.post(`/api/Transaction/Update`, formData, {
                            headers: {
                              "x-access-token": creds.token,
                            },
                          });
                          await getPayDiffTransactions(payDiffTrans?.id!);
                          toast.success(
                            "Votre mise à jour a été effectuée avec succès test",
                            options
                          );
                        } catch (error: any) {
                          ErrorLogger(
                            "updating a transaction's document",
                            error
                          );
                        }
                      }}
                      multiple
                    />
                    <span> Télécharger d'ici</span>
                  </>
                )}
              </label>
            </FormGroup>
            <div className="list-Files">
              {row.documents && row.documents.length > 0 && (
                <>
                  {row.documents.length === 1 &&
                    row.documents.map((document, index) => (
                      <span
                        className="file-box-item transaction-file"
                        key={index}
                      >
                        <FaEye
                          onClick={() => {
                            setUrl(document.url);
                            setViewModal(true);
                          }}
                        />
                        <span>{document.key}</span>
                        <FaTrash
                          onClick={async () => {
                            try {
                              await deleteTransactionDocument(row, document);
                              await getPayDiffTransactions(payDiffTrans?.id!);
                            } catch (error: any) {
                              ErrorLogger(
                                "deleting a transaction's document",
                                error
                              );
                            }
                          }}
                        />
                      </span>
                    ))}
                  {row.documents.length > 1 && (
                    <span
                      className="file-box-item justif-viewer"
                      onClick={() => {
                        setViewDocumentsTransaction(row);
                        setDocumentsModal(true);
                      }}
                    >
                      <FaEye size={20} /> Voir tous
                      {row.factures && row.factures.length > 0 && (
                        <>
                          {" "}
                          <FaFileInvoice size={15}></FaFileInvoice>
                        </>
                      )}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        ),
      },
      {
        name: "Montant",
        sortable: true,
        cell: (row: any) => (
          <span
            className={parseFloat(row.amount) > 0 ? "green-num" : "red-num"}
          >
            {setDecimalDigits(row.amount)} €
          </span>
        ),
      },
      {
        name: "Tva",
        sortable: true,
        omit: !company?.subjectToVAT,
        cell: (row: ITransaction) => {
          let vatPercentage =
            row.vatPercentage !== null && row.vatPercentage !== undefined
              ? `${row.vatPercentage} %`
              : row.sub_category &&
                row.sub_category.TVA &&
                !isNaN(parseFloat(row.sub_category.TVA))
              ? `${parseFloat(row.sub_category.TVA)} %`
              : (null as any);

          if (row.splittedTransactions && row.splittedTransactions.length > 0) {
            vatPercentage = "Mixte";
          }
          return (
            <div className="tva-box">
              <div className="tva-box-input  i-secondary">
                <input
                  key={row.id}
                  name="tva"
                  placeholder="0"
                  className="num-tva"
                  value={
                    singlePayDiffTVA?.id === row.id
                      ? String(singlePayDiffTVA.value)
                      : row.vat
                      ? parseFloat(row.vat).toFixed(2)
                      : ""
                  }
                  type="number"
                  onWheel={(e) => (e.target as any).blur()}
                  disabled={!payDiffEditTVAClick[row.id]!}
                  onChange={(e) =>
                    setSinglePayDiffTVA({
                      id: row.id,
                      value: e.target.value,
                    })
                  }
                />
                <span className="device-tva">€ TVA </span>
                {/* {!payDiffEditTVAClick[row.id] ? (
                  <button
                    className="icon-tva"
                    type="submit"
                    disabled={Object.values(payDiffEditTVAClick).includes(
                      true as any
                    )}
                    onClick={() =>
                      setPayDiffEditTVAClick((prevState: any) => {
                        return {
                          ...prevState,
                          [row.id]: !prevState[row.id],
                        };
                      })
                    }
                  >
                    <img src={tvaEditIcon} alt="icon" />
                  </button>
                ) : ( */}
                <button
                  // className="icon-tva i-blue"
                  // type="submit"
                  // onClick={async () => {
                  //   await editTransactionVat(row);
                  // }}
                  className="icon-tva"
                  type="submit"
                  onClick={() => {
                    setSingleTrans(row);

                    if (
                      row.splittedTransactions &&
                      row.splittedTransactions.length > 0
                    ) {
                      setSingleTVAAmount({ label: "Mixte ", value: "mixte" });
                      setMixteTVASection(true);
                      let _rows = row.splittedTransactions.map((elt) => ({
                        tva_amount: elt.amount,
                        id: elt.id,
                        tva: {
                          value: elt.vatAmount,
                          label: TVAS_OPTIONS.find(
                            (data) => data.value === elt.vatAmount
                          )?.label,
                        },
                      }));
                      setRows(_rows as unknown as TVAFormValues[]);
                    } else {
                      let sub_category_tva_perc =
                        row.vatPercentage !== null &&
                        row.vatPercentage !== undefined
                          ? row.vatPercentage
                          : row["sub_category"] &&
                            row["sub_category"]["TVA"] &&
                            !isNaN(parseFloat(row["sub_category"]["TVA"]))
                          ? parseFloat(
                              parseFloat(row["sub_category"]["TVA"]).toFixed(1)
                            )
                          : null;
                      if (
                        sub_category_tva_perc !== null &&
                        sub_category_tva_perc !== undefined
                      ) {
                        let val = TVAS_OPTIONS.find(
                          (data) => data.value === sub_category_tva_perc
                        );

                        if (val) {
                          setSingleTVAAmount(val);
                        }
                      }
                    }
                    setTVAModal(true);
                  }}
                >
                  {/* <SaveIconWhite /> */}
                  {<ReactSVG src={tvaEditIcon} />}
                  {/* <img src={tvaEditIcon} alt="icon" /> */}
                </button>
                {/* )} */}
              </div>
              {vatPercentage && (
                <span className="mixte-ind">Taux : {vatPercentage}</span>
              )}
            </div>
          );
        },
      },
      {
        name: "Statut",
        selector: (row: any) => row.status,
        omit:
          Object.keys(payDiffNeddValidation).length === 0 ||
          diffPayArchiveModal,
        cell: (row: ITransaction) => (
          <>
            {payDiffNeddValidation[row.id] ? (
              <Button
                color={row.status === 100 ? "warning" : "success"}
                className="btn-status btn-small"
                onClick={async () => {
                  try {
                    if (row.sub_category_id) {
                      if (
                        row.sub_category.name === "Emprunt" &&
                        row.amount <= 0 &&
                        row.status === 100
                      ) {
                        setSingleEmprunt(row);
                        setEmpruntModal(true);
                        return;
                      }

                      const parentCateg = decCategs.find((elt) => {
                        return elt.sub_categories?.find(
                          (elt) => elt.id === row.sub_category_id
                        );
                      });
                      if (
                        parentCateg?.name.includes("Immobilisations") &&
                        !row.numberOfYears
                      ) {
                        setImmob(row);
                        immobReset({
                          transactionAmount: Math.abs(row.amount),
                          transactionDate: moment(row.date).format(
                            "YYYY-MM-DD"
                          ),
                          type: {
                            value: row.sub_category.id,
                            label: `${row.sub_category.name} ${
                              row.sub_category.yearsRange.length > 1
                                ? `(${row.sub_category.yearsRange[0]} à ${row.sub_category.yearsRange[1]} ans)`
                                : `(${row.sub_category.yearsRange[0]} ans)`
                            }`,
                          },
                        });
                        setImmobModal(true);
                        return;
                      }
                    }
                  } catch (error: any) {
                    ErrorLogger("updating a transaction's document", error);
                  }
                }}
              >
                {row.status === 100 ? "À valider" : "Validé"}
              </Button>
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        name: "Action",
        button: true,
        omit: diffPayArchiveModal,
        cell: (row: ITransaction) => (
          <>
            <button
              className="btn btn-red"
              onClick={async () => await deleteTransaction(row.id)}
            >
              <BsTrashFill />
            </button>
          </>
        ),
      },
    ],
    [
      payDiffNeddValidation,
      diffPayArchiveModal,
      payDiffEditTVAClick,
      singlePayDiffTVA,
      billSuggestions,
    ]
  );

  // here we check if the amount of the transaction can allow us to add a facture or not
  const checkCanAddInvoice = (
    invoice: IFacture,
    amount: any,
    alreadyAdded: IFacture[] = []
  ) => {
    let alreadyAddedDocs = [];

    if (alreadyAdded.length > 0) {
      alreadyAddedDocs = alreadyAdded;
    } else {
      alreadyAddedDocs = documents
        .map((elt: File) => elt.name)
        .map((key: string) => {
          const inBillSuggIndex = tempBillSuggestions.findIndex((elt) => {
            console.log("elt.billS3link : ", elt.billS3link, key);
            return elt.billS3link.key === key;
          });
          if (inBillSuggIndex > -1) {
            return tempBillSuggestions[inBillSuggIndex];
          } else {
            return;
          }
        });
    }

    if (alreadyAddedDocs.length === 0) {
      return true;
    }
    return (
      Math.abs(parseFloat(amount)) >=
      [...alreadyAddedDocs, invoice].reduce(
        (acc, curr) => acc + Math.abs(parseFloat(curr.totalTTC)),
        0 as number
      )
    );
  };

  // linking a diff pay / enc diff 's inner trasnaction to a bill
  const addBillToPayDiffTransaction = async (
    facture: IFacture,
    tr: ITransaction | null = null
  ) => {
    try {
      setBillAdd({
        id: facture.id,
        isLoading: true,
      });

      if (!getValues("facture_ids") || getValues("facture_ids")?.length === 0) {
        payDiff_setValue("facture_ids", [facture.id]);
      } else {
        payDiff_setValue("facture_ids", [
          ...getValues("facture_ids")!,
          facture.id,
        ]);
      }

      const { data } = await api.get(facture.billS3link.url, {
        responseType: "blob",
      });

      if (data) {
        const file = new File([data], facture.billS3link.key, {
          type: data.type,
        });

        if (facture.transactions && facture.transactions.length > 0) {
          const parentTransactionIndex = facture.transactions.findIndex(
            (elt) => elt.relatedToFactureType === "parent"
          );

          if (parentTransactionIndex > -1) {
            const parentTransaction =
              facture.transactions[parentTransactionIndex];

            payDiff_setValue("relatedToFactureType", "child");
            setDocuments((prevState: any) => {
              return [...(prevState || []), file];
            });
            if (tr) {
              const formData = new FormData();
              formData.append("id", tr.id);
              formData.append("documents", file);
              formData.append("relatedToFactureType", "child");
              const { data: updatedTr } = await api.post(
                `/api/transaction/transactionUpdate`,
                formData,
                {
                  headers: {
                    "x-access-token": creds.token,
                  },
                }
              );

              if (
                updatedTr.updated &&
                updatedTr.updated.length > 0 &&
                updatedTr.updated[0].view &&
                updatedTr.updated[0].view.id
              ) {
                await linkTransactionstoBill(tr.id, [facture.id]);
              }
              setBillAdd(null);
              toast.dismiss();
              toast.success(`Votre facture a été ajoutée avec succès.`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        }
      }
      await getPayDiffTransactions(payDiffTrans?.id!);
      if (
        company &&
        company.pack &&
        NonImmoPacks.includes(company?.pack! as any)
      ) {
        await checkTransactionsBills(context!);
      }
    } catch (error: any) {
      ErrorLogger("archiving a transaction", error);
      setBillAdd(null);
    }
  };

  const getSingleTransaction = async (id: string) => {
    try {
      let { data: transaction } = await api.post(
        `/api/Transaction`,
        {
          id: id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      return transaction;
    } catch (error) {
      console.log("getSingleTransaction : ", error);
    }
  };

  // here we are making an implicit single trasnaction state change to the array of transactions in the state
  const singleTransactionStateInsertion = async (id: string) => {
    try {
      const { data: transaction } = await api.post(
        `/api/Transaction`,
        {
          id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      implicitStateChange(transaction, transaction.id);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting non valid transactions", error);
    }
  };

  const documentsColumns = [
    {
      name: "Document",
      selector: (row: any) => row.key,
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (row: IS3) => (
        <div className="table-action">
          <>
            <button
              className="btn btn-blue"
              onClick={() => {
                setUrl(row.url);
                setViewModal(true);
              }}
              disabled={
                billDelete! &&
                billDelete?.key === row.key &&
                billDelete?.isLoading
              }
            >
              <ViewIcon />
            </button>
            <button
              className="btn btn-red"
              onClick={async () => {
                await deleteTransactionDocument(viewDocumentsTransaction!, row);
              }}
              disabled={
                billDelete! &&
                billDelete?.key === row.key &&
                billDelete?.isLoading
              }
            >
              {billDelete &&
              billDelete.key === row.key &&
              billDelete.isLoading ? (
                <Spinner color="danger" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                <BsTrashFill />
              )}
            </button>
          </>
        </div>
      ),
    },
  ];

  // gitting factures
  const checkTransactionsBills = async (company_id: string) => {
    if (!company_id) {
      return;
    }
    try {
      const { data } = await api.post(
        `/api/Facture/All`,
        {
          where: {
            company_id: company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setTempBillSuggestions([...data.data]);
      setBillSuggestions([...data.data]);
      setTotalBillRows(data.count);
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
    }
  };

  // here we are linking a trasnaction to a facture (making a join)
  const linkTransactionstoBill = async (
    transactionId: string,
    factureIds: string[]
  ) => {
    try {
      for (let factureId of factureIds) {
        await api.post(
          `/api/Facture_Transaction/Create`,
          {
            TransactionId: transactionId,
            FactureId: factureId,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      toast.dismiss();
      toast.success(`Votre facture a été ajoutée avec succès.`, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return true;
    } catch (error: any) {
      ErrorLogger("creating a Facture_Transaction", error);
    }
  };

  const unlinkTransactionstoBill = async (
    factureId: string,
    transactionId: string
  ) => {
    try {
      const returnValue = await api.post(
        `/api/transaction/unlinkTransactionstoBill`,
        {
          FactureId: factureId,
          TransactionId: transactionId,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      toast.dismiss();
      toast.success(`Votre facture a été supprimée avec succès.`, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("creating a Facture_Transaction", error);
    }
  };

  //here we are just adding the file of the facture to transaction documents
  const addBillToTransaction = async (tr: ITransaction, facture: IFacture) => {
    try {
      setBillAdd({
        id: facture.id,
        isLoading: true,
      });

      let updatedTransaction = null;

      const { data } = await api.get(facture.billS3link.url, {
        responseType: "blob",
      });

      if (data) {
        const file = new File([data], facture.billS3link.key);
        const formData = new FormData();

        formData.append("id", tr.id);
        formData.append("documents", file);
        formData.append("relatedToFactureType", "child");

        const { data: updatedTr } = await api.post(
          `/api/transaction/transactionUpdate`,
          formData,
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );

        updatedTransaction = updatedTr;

        if (
          updatedTr.updated &&
          updatedTr.updated.length > 0 &&
          updatedTr.updated[0].view &&
          updatedTr.updated[0].view.id
        ) {
          await linkTransactionstoBill(tr.id, [facture.id]);
        }
      }

      setBillAdd(null);

      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans?.id!);
      }

      if (
        updatedTransaction.updated &&
        updatedTransaction.updated.length > 0 &&
        updatedTransaction.updated[0].id === tr.id &&
        updatedTransaction.updated[0].view &&
        updatedTransaction.updated[0].view.id
      ) {
        const afterUpdateTransaction = await getSingleTransaction(
          updatedTransaction.updated[0].view.id
        );
        if (payDiffTrans && payDiffTrans.id) {
          implicitStateChange(payDiffTrans, payDiffTrans?.id!, true);
        } else {
          implicitStateChange(afterUpdateTransaction, tr.id);
        }
      } else {
        if (payDiffTrans && payDiffTrans.id) {
          await getPayDiffTransactions(payDiffTrans?.id!);
        } else {
          await getTransactions(
            transactionNameSearch && transactionNameSearch !== ""
              ? transactionNameSearch
              : ""
          );
        }
      }

      if (
        company &&
        company.pack &&
        NonImmoPacks.includes(company?.pack! as any)
      ) {
        await checkTransactionsBills(context!);
      }
    } catch (error: any) {
      ErrorLogger("archiving a transaction", error);
      setBillAdd(null);
    }
  };

  // updating the facture (associated to a transaction)'s status to be payed if the amount of the transaction is greater to the facture amount or the facture has been paied with multiple transactions
  const editInvoiceTransactionStatus = async (
    factureID: string,
    status: string
  ) => {
    try {
      await api.post(
        `/api/Facture/Update`,
        {
          id: factureID,
          status,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
    } catch (error: any) {
      ErrorLogger("updating transaction tva", error);
    }
  };

  // apart from detatching the transaction from the bill,the bill will be unpaid
  const detatchTransactionFromInvoice = async (
    row: ITransaction,
    docIndex: number
  ) => {
    try {
      if (row.factures && row.factures.length > 0) {
        const factureToDePay = row.factures[docIndex];

        if (row.factures.length === 1) {
          let payload: any = {
            id: row.id,
            relatedToFactureType: null,
          };

          await api.post(`/api/Transaction/Update`, payload, {
            headers: {
              "x-access-token": creds.token,
            },
          });
        }

        if (factureToDePay) {
          await editInvoiceTransactionStatus(factureToDePay.id, "102");
          await unlinkTransactionstoBill(factureToDePay.id, row.id);
        }
      }
    } catch (error: any) {
      ErrorLogger("archiving a transaction", error);
    }
  };

  const deleteTransaction = async (id: string = "") => {
    try {
      const { data } = await api.post(
        `/api/Transaction/Delete`,
        {
          id: id !== "" ? id : transactionId,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setTransactionsToValid((prevState) =>
        prevState.filter((trans) => trans.id !== transactionId)
      );
      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans?.id!);
      }
      setTransactionId(null);
      setOpen(false);
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
    }
  };

  const deletePayDiffTransaction = async (id: string) => {
    try {
      const { data } = await api.post(
        `/api/Transaction/deleteWhere`,
        {
          where: {
            diffPay_id: [id],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
    }
  };

  const validatePayDiffTransactions = async () => {
    try {
      setErrorMessage(null);
      setPayDiffValidation(true);

      const subCategs = [
        ...encCategs.reduce(
          (acc, curr) => [...acc, ...curr.sub_categories!],
          [] as ISubCategory[]
        ),
        ...decCategs.reduce(
          (acc, curr) => [...acc, ...curr.sub_categories!],
          [] as ISubCategory[]
        ),
      ];

      if (payDiffTransArray.length > 0) {
        let issued = [] as string[];
        payDiffTransArray.forEach((tr) => {
          const parentCateg = subCategs.find((elt) => {
            return elt.id === tr.sub_category_id;
          });
          const check =
            (parentCateg?.name.includes("Immobilisations") &&
              !tr.numberOfYears) ||
            (tr.sub_category.name === "Emprunt" &&
              tr.amount <= 0 &&
              tr.status === 100);

          if (check) {
            issued.push(tr.id);
          }
        });

        if (issued.length > 0) {
          for (let id of issued) {
            setPayDiffNeddValidation((prevState: any) => {
              return {
                ...prevState,
                [id]: !prevState[id],
              };
            });
          }
          setErrorMessage({
            type: "payDiff_need_validation",
            message:
              "Une ou plusieurs transactions doivent être validées manuellement",
          });
        } else {
          const { data } = await api.post(
            `/api/Transaction/Update`,
            {
              id: payDiffTrans?.id,
              status: 101,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );
          // for (let tr of payDiffTransArray) {
          //   if (tr.status === 100) {
          //     await api.post(
          //       `/api/Transaction/Update`,
          //       {
          //         id: tr.id,
          //         status: 101,
          //       },
          //       {
          //         headers: {
          //           "x-access-token": creds.token,
          //         },
          //       }
          //     );
          //   }
          // }
          setPayDiffNeddValidation({});
          await getTransactions(
            transactionNameSearch && transactionNameSearch !== ""
              ? transactionNameSearch
              : ""
          );
          await getPayDiffTransactions(payDiffTrans?.id!);
          setDiffPayValidateModal(false);
          setPayDiffTrans(null);
          setPayDiffSubCateg(null);
        }
      }

      setPayDiffValidation(false);
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
      await getTransactions(
        transactionNameSearch && transactionNameSearch !== ""
          ? transactionNameSearch
          : ""
      );
      setPayDiffValidation(false);
      setPayDiffNeddValidation({});
    }
  };

  const archiveTransaction = async (id: string) => {
    try {
      await api.post(
        `/api/Transaction/Update`,
        {
          id,
          status: company && company.pack === AppPacks.Entrepreneur ? 104 : 102,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setTransactionsToValid((prevState) =>
        prevState.filter((trans) => trans.id !== id)
      );

      // if (payDiffTrans && payDiffTrans.id) {
      //   await api.post(
      //     `/api/Transaction/UpdateWhere`,
      //     {
      //       where: {
      //         diffPay_id: id,
      //       },
      //       data: {
      //         status:
      //           company && company.pack === AppPacks.Entrepreneur ? 104 : 102,
      //       },
      //     },
      //     {
      //       headers: {
      //         "x-access-token": creds.token,
      //       },
      //     }
      //   );
      // }
    } catch (error: any) {
      ErrorLogger("archiving a transaction", error);
    }
  };

  const validateTransaction = async (row: ITransaction) => {
    try {
      let payload: {
        id: string;
        status: number;
        numberOfYears?: null;
      } = {
        id: row.id,
        status: row.status === 100 ? 101 : 100,
      };
      if (row.numberOfYears && row.status === 101) {
        payload = {
          ...payload,
          numberOfYears: null,
        };
      }

      console.log("payload", payload);

      const { data: transactionUpdate } = await api.post(
        `/api/Transaction/Update`,
        payload,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      // if (
      //   row.status === 101 &&
      //   row.sub_category &&
      //   (row.sub_category.key === PayDiffKeys.OUT101 ||
      //     row.sub_category.key === PayDiffKeys.OUT100)
      // ) {
      //   await api.post(
      //     `/api/Transaction/UpdateWhere`,
      //     {
      //       where: {
      //         diffPay_id: row.id,
      //       },
      //       data: {
      //         status: 100,
      //       },
      //     },
      //     {
      //       headers: {
      //         "x-access-token": creds.token,
      //       },
      //     }
      //   );
      // }
      if (
        transactionUpdate.updated &&
        transactionUpdate.updated.length > 0 &&
        transactionUpdate.updated[0].id === row.id &&
        transactionUpdate.updated[0].view &&
        transactionUpdate.updated[0].view.id
      ) {
        implicitStateChange(transactionUpdate.updated[0].view, row.id);
      } else {
        await getTransactions(
          transactionNameSearch && transactionNameSearch !== ""
            ? transactionNameSearch
            : ""
        );
      }
    } catch (error: any) {
      ErrorLogger("archiving a transaction", error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [currentBillPage, setCurrentBillPage] = useState(1);

  const implicitStateChange = (
    newState: ITransaction,
    id: string,
    isPayDiff: boolean = false
  ) => {
    if (isPayDiff) {
      setPAyDiffTransArray((prevState) => {
        return prevState
          .map((el) => {
            return el.id === id
              ? {
                  ...newState,
                }
              : el;
          })
          .sort((a, b) => {
            return moment(b.date).diff(moment(a.date));
          });
      });
    } else {
      setTransactionsToValid((prevState) => {
        return prevState
          .map((el) => {
            return el.id === id
              ? {
                  ...newState,
                }
              : el;
          })
          .sort((a, b) => {
            return moment(b.date).diff(moment(a.date));
          });
      });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    // await getTransactions(page);
  };
  const handleBillPageChange = (page: number) => {
    setCurrentBillPage(page);
  };

  const getTransactions = async (
    nameSearch: string = "",
    isReloaded: boolean = false
  ) => {
    if (!context) {
      return;
    }
    try {
      callback(false);
      if (!isReloaded) {
        setPending(true);
      }
      let where: any = {
        status: [100, 101],
        companyId: context,
        diffPay_id: {
          isNull: true,
        },
        relatedToFactureType: {
          or: [null, "child"],
        },
      };

      if (nameSearch && nameSearch !== "") {
        where = {
          ...where,
          label: {
            iLike: `%${nameSearch}%`,
          },
        };
      }

      const { data } = await api.post(
        `/api/Transaction/All`,
        {
          where,
          perPage: 20,
          pageIndex: currentPage,
          order: [
            ["date", "DESC"],
            ["id", "DESC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: transactionsData } = data;

      setTransactionsToValid(
        transactionsData.sort((a: { date: number }, b: { date: number }) => {
          return moment(b.date).diff(moment(a.date));
        })
      );
      for (let elt of transactionsData) {
        setEditTVAClick((prevState: any) => {
          return {
            ...prevState,
            [elt.id]: false,
          };
        });
        setEditDateClick((prevState: any) => {
          return {
            ...prevState,
            [elt.id]: false,
          };
        });
      }

      setTotalRows(data.count);
      setPending(false);
      return transactionsData;
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting non valid transactions", error);
    }
  };

  const getPayDiffTransactions = async (payDiffId: string) => {
    try {
      const { data } = await api.post(
        `/api/Transaction/All`,
        {
          where: {
            diffPay_id: payDiffId,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: transactionsData } = data;

      setPAyDiffTransArray(
        transactionsData.sort((a: { date: number }, b: { date: number }) => {
          return moment(b.date).diff(moment(a.date));
        })
      );
      for (let elt of transactionsData) {
        setPayDiffEditTVAClick((prevState: any) => {
          return {
            ...prevState,
            [elt.id]: false,
          };
        });
      }

      return transactionsData;
    } catch (error: any) {
      ErrorLogger("getting pay diff non valid transactions", error);
    }
  };

  const getCategories = async () => {
    try {
      const { data } = await api.post(
        `/api/Category/All`,
        {},
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const enc = data.data.filter(
        (elt: ICategory) => elt.type === "Encaissement"
      );
      const dec = data.data.filter(
        (elt: ICategory) => elt.type === "Décaissement"
      );

      setDecCategs(dec);
      setEncCategs(enc);
    } catch (error: any) {
      ErrorLogger("getting non valid transactions", error);
    }
  };

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SearchFormValues>({});

  const { ref: nameRef, ...name } = register("name");

  const {
    control: immobControl,
    setValue: immobSetValue,
    watch: immobWatch,
    register: immobRegister,
    handleSubmit: immobHandleSubmit,
    reset: immobReset,
    formState: { errors: immobErrors },
  } = useForm<ImmobFormValues>({});

  const {
    control: empruntControl,
    setValue: empruntSetValue,
    watch: empruntWatch,
    register: empruntRegister,
    handleSubmit: empruntHandleSubmit,
    reset: empruntReset,
    formState: { errors: empruntErrors },
  } = useForm<EmpruntFormValues>({});

  const { ref: transactionAmountRef, ...transactionAmount } =
    immobRegister("transactionAmount");
  const { ref: transactionDateRef, ...transactionDate } =
    immobRegister("transactionDate");
  const { ref: numberOfYearsRef, ...numberOfYears } =
    immobRegister("numberOfYears");

  const { ref: capitalAmountRef, ...capitalAmount } =
    empruntRegister("capitalAmount");
  const { ref: chargeAmountRef, ...chargeAmount } =
    empruntRegister("chargeAmount");
  const { ref: insuranceAmountRef, ...insuranceAmount } =
    empruntRegister("insuranceAmount");

  const searhcCategs = async () => {
    try {
      if (!searchCategName) {
        setCloseCategSearch(false);
        await getCategories();
        return;
      }
      if (!closeCategSearch) {
        setCloseCategSearch(true);
      }

      if (categStatus === "dec" || categRemb) {
        const allSubs = decCategs.reduce(
          (acc, curr) => [...acc, ...(curr.sub_categories || [])],
          [] as ISubCategory[]
        );
        let found: ISubCategory[] = [];
        for (let elt of allSubs) {
          const exists = elt.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              searchCategName
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")!
            );

          if (exists) {
            found.push(elt);
          }
        }
        setDecCategs(
          found.length > 0
            ? [{ ...decCategs[0], sub_categories: found }]
            : decCategs
        );
      }

      if (categStatus === "enc" || categRemb) {
        for (let elt of encCategs) {
          if (elt.sub_categories) {
            const exists: ISubCategory[] = elt.sub_categories?.filter((elt) =>
              elt.name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  searchCategName
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")!
                )
            );
            if (exists.length > 0) {
              setEncCategs((prevState) => [
                {
                  ...prevState[0],
                  sub_categories:
                    exists.length > 0 ? exists : prevState[0].sub_categories,
                },
              ]);
            }
          }
        }
      }
    } catch (error: any) {
      ErrorLogger("searching categs", error);
    }
  };

  const editTransactionVat = async (transaction: ITransaction) => {
    const options: any = {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    try {
      let res;
      setPayDiffEditTVAClick((prevState: any) => {
        return {
          ...prevState,
          [transaction.id]: false,
        };
      });
      if (payDiffTrans && payDiffTrans.id) {
        const value = Math.abs(
          parseFloat(
            singlePayDiffTVA && singlePayDiffTVA?.id === transaction.id
              ? String(singlePayDiffTVA.value)
              : transaction?.vat
          )
        );
        res = await api.post(
          `/api/Transaction/Update`,
          {
            id: transaction.id,
            vat: transaction.amount > 0 ? value : -value,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        await getPayDiffTransactions(payDiffTrans.id!);
        setSinglePayDiffTVA(null);
      } else {
        res = await api.post(
          `/api/Transaction/Update`,
          {
            id: transaction.id,
            vat:
              transaction.amount > 0
                ? Math.abs(Number(singleTVA?.value))
                : -Math.abs(Number(singleTVA?.value)),
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        implicitStateChange(res.data.updated[0].view, res.data.updated[0].id);
        setSingleTVA({
          id: "",
          value: "",
        });
      }
    } catch (error: any) {
      ErrorLogger("updating transaction tva", error);
      toast.error(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        options
      );
      setSingleTVA((prevState) => {
        return {
          ...prevState,
          value: transactionsToValid.find(
            (tvaItem) => tvaItem.id === prevState!.id
          )!.vat,
        };
      });
    }
    setEditTVAClick((prevState: any) => {
      return {
        ...prevState,
        [transaction.id]: false,
      };
    });
  };

  const editTransactionDate = async (transaction: ITransaction) => {
    const options: any = {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    try {
      if (
        moment(singleDate?.value).format("YYYY-MM-DD") ===
        moment(transaction.date).format("YYYY-MM-DD")
      ) {
        await getTransactions(
          transactionNameSearch && transactionNameSearch !== ""
            ? transactionNameSearch
            : ""
        );

        setEditDateClick((prevState: any) => {
          return {
            ...prevState,
            [singleDate?.id!]: false,
          };
        });
        setSingleDate(null);
        return;
      }
      const { data: transactionDateUpdate } = await api.post(
        `/api/Transaction/Update`,
        {
          id: singleDate?.id,
          date: moment(singleDate?.value).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      implicitStateChange(
        transactionDateUpdate.updated[0].view,
        transactionDateUpdate.updated[0].id
      );

      setSingleDate(null);

      toast.dismiss();
      toast.success(
        "Votre mise à jour a été effectuée avec succès date",
        options
      );
    } catch (error: any) {
      ErrorLogger("updating transaction tva", error);
      toast.error(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        options
      );
    }
    setEditDateClick((prevState: any) => {
      return {
        ...prevState,
        [singleDate?.id!]: false,
      };
    });
  };

  // adding the necessary logic for an immobilisation transaction (transaction with immobiliation category)
  const editImmobTransaction: SubmitHandler<ImmobFormValues> = async (
    form: ImmobFormValues
  ) => {
    try {
      setLoading(true);
      if (
        !form.numberOfYears ||
        !form.transactionAmount ||
        !form.transactionDate ||
        !form.type
      ) {
        setLoading(false);
        setErrorMessage({
          type: "invalid_immob_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }
      const chosenType = form.type.value;

      const immobilisations = decCategs.find(
        (elt) => elt.name === "Immobilisations"
      )?.sub_categories;

      if (immobilisations) {
        const chosenSubCateg = immobilisations.find(
          (elt) => elt.id === chosenType
        );

        if (
          (Array.isArray(chosenSubCateg?.yearsRange) &&
            chosenSubCateg?.yearsRange.length! > 1 &&
            (Number(form.numberOfYears) < chosenSubCateg?.yearsRange[0]! ||
              Number(form.numberOfYears) > chosenSubCateg?.yearsRange[1]!)) ||
          (Array.isArray(chosenSubCateg?.yearsRange) &&
            chosenSubCateg?.yearsRange.length == 1 &&
            String(chosenSubCateg?.yearsRange[0]) !==
              String(form.numberOfYears))
        ) {
          setErrorMessage({
            type: "invalid_immob_form",
            message:
              "Veuillez choisir un nombre d'années correspondant au type choisi",
          });
          setLoading(false);
          return;
        }
        await api.post(
          `/api/Transaction/Update`,
          {
            id: singleImmob?.id,
            assetType: chosenType,
            createAsset: true,
            numberOfYears: Number(form.numberOfYears),
            status: 101,
            // amount: -Math.abs(form.transactionAmount),
            amount: form.transactionAmount,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      setImmob(null);
      immobReset();
      await getTransactions(
        transactionNameSearch && transactionNameSearch !== ""
          ? transactionNameSearch
          : "",
        true
      );
      setLoading(false);
      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans.id);
      }
      setImmobModal(false);
      setErrorMessage(null);
    } catch (error: any) {
      ErrorLogger("updating transaction immob", error);
    }
  };
  // adding the necessary logic for an emprunt transaction (transaction with emprunt category)
  const editEmpruntTransaction: SubmitHandler<EmpruntFormValues> = async (
    form: EmpruntFormValues
  ) => {
    try {
      setErrorMessage(null);
      setLoading(true);
      if (!form.capitalAmount && !form.insuranceAmount && !form.chargeAmount) {
        setLoading(false);
        setErrorMessage({
          type: "invalid_emprunt_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }
      if (
        parseFloat(
          Math.abs(
            (parseFloat(String(form.capitalAmount)) || 0) +
              (parseFloat(String(form.insuranceAmount)) || 0) +
              (parseFloat(String(form.chargeAmount)) || 0)
          ).toFixed(2)
        ) !== parseFloat(Math.abs(singleEmprunt?.amount!).toFixed(2))
      ) {
        setLoading(false);
        setErrorMessage({
          type: "invalid_emprunt_form",
          message:
            "Les montants proposés ne correspondent pas au montant de la transaction sélectionnée.",
        });
        return;
      }
      await api.post(
        `/api/Transaction/Update`,
        {
          id: singleEmprunt?.id,
          capitalAmount: form.capitalAmount || 0,
          chargeAmount: form.chargeAmount || 0,
          insuranceAmount: form.insuranceAmount || 0,
          status: 101,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setSingleEmprunt(null);
      empruntReset();
      await getTransactions(
        transactionNameSearch && transactionNameSearch !== ""
          ? transactionNameSearch
          : "",
        true
      );
      setLoading(false);
      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans.id);
      }
      setEmpruntModal(false);
      setErrorMessage(null);
    } catch (error: any) {
      ErrorLogger("updating transaction immob", error);
    }
  };

  const resetStates = () => {
    setCloseCategSearch(false);
    reset();
    setSearchCategName(null);
    setOpenCategModal(false);
    setCategremb(false);
    // setSingleTrans(null);
    setIsImmobModal(false);
  };

  const immobCheckCallback = async (categ: ISubCategory) => {
    try {
      setIsImmobCateg(categ);
      await getCategories();
      setOpenCategModal(false);
      setIsImmobModal(true);
    } catch (error: any) {
      ErrorLogger("switching to immob", error);
    }
  };

  const carbCheckCallback = (transaction: ITransaction) => {
    try {
      setOpenCategModal(false);
      setIsCarbModal(true);
      setSingleCarb(transaction);
    } catch (error: any) {
      ErrorLogger("switching to immob", error);
    }
  };
  // adding the necessary logic for an carburant transaction (transaction with carburant category)
  const editCarbTransaction = async (vehType: String) => {
    try {
      const options: any = {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };
      toast.dismiss();
      setCarbLoading(true);
      toast.warning(
        "Cela peut prendre quelques secondes, veuillez patienter...",
        options
      );
      let { data: carbCategs } = await api.post(
        `/api/SubCategory/All`,
        {
          where: {
            key: CarbKeys.OUT028,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (carbCategs && carbCategs.data.length > 0) {
        const carbCateg = carbCategs.data[0] as ISubCategory;
        const carbTVA = setTVA(
          singleCarb?.amount!,
          parseFloat(carbCateg.TVA) / 100
        );
        await api.post(
          `/api/Transaction/Update`,
          {
            id: singleCarb?.id,
            carbVehType: vehType,
            subCategoryId: carbCateg.id,
            category: CarbKeys.OUT028,
            vat:
              carbCateg.TVA &&
              !isNaN(parseFloat(carbCateg.TVA)) &&
              parseFloat(carbCateg.TVA) !== 0
                ? vehType === CarbVehTypes.Tourisme
                  ? carbTVA * 0.8
                  : carbTVA
                : null,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      await getTransactions(
        transactionNameSearch && transactionNameSearch !== ""
          ? transactionNameSearch
          : ""
      );
      setCarbLoading(false);
      setSingleCarb(null);
      setIsCarbModal(false);
    } catch (error: any) {
      ErrorLogger("updating transaction carb", error);
      setCarbLoading(false);
    }
  };

  const removeTransactionDiffCateg = async (transaction: ITransaction) => {
    try {
      const res = await api.post(
        `/api/Transaction/Update`,
        {
          id: transaction?.id,
          sub_category_id: null,
          category: null,
          vat: null,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      payDiffStateHandler?.setIsPayDiffSubCateg(null);
      payDiffStateHandler?.setIsPayDiffTrans(null);
      if (
        res.data &&
        res.data.updated &&
        res.data.updated.length > 0 &&
        res.data.updated[0].view &&
        res.data.updated[0].id
      ) {
        implicitStateChange(res.data.updated[0].view, res.data.updated[0].id);
      } else {
        await getTransactions(
          transactionNameSearch && transactionNameSearch !== ""
            ? transactionNameSearch
            : ""
        );
      }

      await api.post(
        `/api/Transaction/DeleteWhere`,
        {
          where: {
            diffPay_id: transaction?.id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
    } catch (error: any) {
      ErrorLogger("removing transaction diff pay", error);
      setCarbLoading(false);
    }
  };

  const diffPayCheckCallback = async (
    transaction: ITransaction,
    subCateg: ISubCategory
  ) => {
    try {
      console.log({ transaction });
      setOpenCategModal(false);
      setPayDiffTrans(transaction);
      setPayDiffSubCateg(subCateg);
      await getPayDiffTransactions(transaction.id);
      setDiffPayModal(true);
    } catch (error: any) {
      ErrorLogger("switching to diff pay", error);
    }
  };

  const diffPayCheckValidate = async (transaction: ITransaction) => {
    try {
      setPayDiffTrans(transaction);
      await getPayDiffTransactions(transaction.id);
      setDiffPayValidateModal(true);
    } catch (error: any) {
      ErrorLogger("switching to diff pay", error);
    }
  };

  const diffPayCheckArchive = async (transaction: ITransaction) => {
    try {
      setPayDiffTrans(null);
      (await getPayDiffTransactions(transaction.id)) as ITransaction[];
      setDiffPayArchiveModal(true);
      setPayDiffTrans(transaction);
    } catch (error: any) {
      ErrorLogger("switching to diff pay", error);
    }
  };

  const categCallback = async () => {
    await getCategories();
    await getTransactions(
      transactionNameSearch && transactionNameSearch !== ""
        ? transactionNameSearch
        : "",
      true
    );

    if (payDiffTrans && payDiffTrans.id) {
      await getPayDiffTransactions(payDiffTrans.id);
    }

    resetStates();
  };

  const { setTVA } = useTVA();

  const immobChange = async () => {
    try {
      if (singleTrans && isImmobCateg?.id) {
        let categArray = singleTrans.amount > 0 ? encCategs : decCategs;
        for (let elt of categArray) {
          const subCategs = elt.sub_categories;
          const found = subCategs?.find(
            (elt) => elt.id === isImmobCateg.redirectCategory_id
          );
          if (found) {
            await api.post(
              `/api/Transaction/Update`,
              {
                id: singleTrans?.id,
                subCategoryId: found?.id,
                category: found?.key,
                vat:
                  found?.TVA &&
                  !isNaN(parseFloat(found?.TVA)) &&
                  parseFloat(found?.TVA) !== 0
                    ? setTVA(singleTrans?.amount!, parseFloat(found?.TVA) / 100)
                    : null,
              },
              {
                headers: {
                  "x-access-token": creds.token,
                },
              }
            );
          }
        }
      }
      setRefreshCategs(false);
      setIsImmobCateg(null);
      await categCallback();
    } catch (error: any) {
      ErrorLogger("switching to immob", error);
    }
  };

  useEffect(() => {
    getCategories();

    if (
      company &&
      company.pack &&
      NonImmoPacks.includes(company?.pack! as any)
    ) {
      checkTransactionsBills(context!);
    }
  }, []);

  useEffect(() => {
    if (reload || creds.company_id) {
      setPending(true);
      handlePageChange(1);
      if (transactionNameSearch && transactionNameSearch !== "") {
        setTransactionNameSearch("");
      }
      getTransactions();
      getCategories();
      if (
        company &&
        company.pack &&
        NonImmoPacks.includes(company?.pack! as any)
      ) {
        checkTransactionsBills(context!);
      }
      setPending(false);
    }
  }, [reload, creds.company_id]);

  useEffect(() => {
    if (
      immobStateHandler?.isImmobFire &&
      immobStateHandler?.isImmobSubCateg &&
      immobStateHandler?.isImmobTrans
    ) {
      setSingleTrans(immobStateHandler?.isImmobTrans);
      immobCheckCallback(immobStateHandler?.isImmobSubCateg!);
      immobStateHandler?.setIsImmobFire(false);
    }
  }, [immobStateHandler]);

  useEffect(() => {
    if (
      payDiffStateHandler?.isPayDiffFire &&
      payDiffStateHandler?.isPayDiffSubCateg &&
      payDiffStateHandler?.isPayDiffTrans &&
      payDiffStateHandler.isPayDiffFire
    ) {
      diffPayCheckCallback(
        payDiffStateHandler?.isPayDiffTrans,
        payDiffStateHandler?.isPayDiffSubCateg
      );
      payDiffStateHandler?.setIsPayDiffFire(false);
    }
  }, [payDiffStateHandler]);

  useEffect(() => {
    searhcCategs();
  }, [searchCategName]);

  useEffect(() => {
    if (transactionNameSearch) {
      getTransactions(transactionNameSearch);
    } else {
      getTransactions();
    }
  }, [currentPage]);

  useEffect(() => {
    if (context) {
      if (
        company &&
        company.pack &&
        NonImmoPacks.includes(company?.pack! as any)
      ) {
        checkTransactionsBills(context!);
      }
    }
  }, [currentBillPage]);

  useEffect(() => {
    if (transactionNameSearch) {
      getTransactions(transactionNameSearch);
    }
  }, [transactionNameSearch]);

  useEffect(() => {
    if (factureNameSearch) {
      let factureArray = [...billSuggestions];
      let filtredFactures = factureArray.filter(
        (elt) =>
          elt.id.toLowerCase().includes(factureNameSearch.toLowerCase()) ||
          (elt.clientInfo &&
            elt.clientInfo.clientName &&
            elt.clientInfo.clientName !== "" &&
            elt.clientInfo.clientName
              .toLowerCase()
              .includes(factureNameSearch.toLowerCase())) ||
          elt.totalHT.toLowerCase().includes(factureNameSearch.toLowerCase()) ||
          elt.totalTTC.toLowerCase().includes(factureNameSearch.toLowerCase())
      );
      setBillSuggestions([...filtredFactures]);
      setTotalBillRows(filtredFactures.length);
    } else {
      checkTransactionsBills(context!);
    }
  }, [factureNameSearch]);

  const {
    watch: payDiff_watch,
    control: payDiff_control,
    register: payDiff_register,
    handleSubmit: payDiff_handleSubmit,
    reset: payDiff_reset,
    setValue: payDiff_setValue,
    formState: { errors: payDiff_errors },
    getValues,
  } = useForm<GeneralFormValues>({});

  const { documents: _document, category: _category } = payDiff_watch();

  const { ref: labelRef, ...label } = payDiff_register("label");
  const { ref: amountRef, ...amount } = payDiff_register("amount");
  const { ref: dateRef, ...date } = payDiff_register("date");
  const { ref: documentRef, ...document } = payDiff_register("documents");

  // creating a transaction (for diff pay / enc pay transactions)
  const createTransactionLogic = async (
    form: GeneralFormValues,
    sign: String
  ) => {
    try {
      setLoading(true);
      setErrorMessage(null);
      setPayDiffCreateLoading(true);

      if (!form.label || !form.amount) {
        setLoading(false);
        setPayDiffCreateLoading(false);
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }

      const formData = new FormData();

      let amount = form.amount;

      // forcing the transaction sign
      if (form.sign && form.sign === "neg") {
        amount = -amount!;
      }

      let type = amount! > 0 ? "cash" : "expense";

      formData.append("label", form.label as string);
      formData.append("status", 100 as any);
      formData.append("companyId", context as string);
      formData.append(
        "date",
        moment(payDiffTrans?.date).format("YYYY-MM-DD") as string
      );
      formData.append("medium", TransactionMediums.Manual);

      formData.append("diffPay_id", payDiffTrans?.id!);

      const subCategs = [
        ...encCategs.reduce(
          (acc, curr) => [...acc, ...curr.sub_categories!],
          [] as ISubCategory[]
        ),
        ...decCategs.reduce(
          (acc, curr) => [...acc, ...curr.sub_categories!],
          [] as ISubCategory[]
        ),
      ];

      if (form.carbVehType && form.carbVehType.value) {
        formData.append("carbVehType", form.carbVehType.value);
      }

      if (form.category) {
        const categIndex = subCategs.findIndex(
          (elt) => elt.id === form.category?.value
        );
        if (categIndex > -1) {
          formData.append("sub_category_id", form.category?.value as string);
          formData.append("category", subCategs[categIndex].key as string);
        }

        if (
          subCategs[categIndex]?.TVA &&
          !isNaN(parseFloat(subCategs[categIndex]?.TVA)) &&
          parseFloat(subCategs[categIndex]?.TVA) !== 0
        ) {
          const vatAmm = setTVA(
            amount!,
            parseFloat(subCategs[categIndex]?.TVA) / 100
          );
          const vat =
            form.carbVehType &&
            form.carbVehType.value &&
            form.carbVehType.value === CarbVehTypes.Tourisme
              ? vatAmm * 0.8
              : vatAmm;

          formData.append("vat", String(vat));
        } else {
          formData.append("vat", "0");
        }
      }

      if (form.vat) {
        formData.append("vat", form.vat);
      }

      if (form.sub_category_id) {
        formData.append("sub_category_id", form.category?.value as string);
      }

      formData.append("amount", amount as any);
      formData.append("type", type as any);

      if (documents && documents.length) {
        for (let index = 0; index < documents.length; index++) {
          const element = documents[index];
          formData.append("documents", element);
        }
      }

      // to identify the transaction as a transaction paying for a bill
      if (form.relatedToFactureType === "child") {
        formData.append("relatedToFactureType", "child");
      }

      const { data } = await api.post(`/api/Transaction/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      await getPayDiffTransactions(payDiffTrans?.id!);
      setLoading(false);
      setPayDiffCreateLoading(false);
      setAddNoteFraisModal(false);
      setAddEncModal(false);

      // if (data.id && data.vat) {
      //   await updateParentPayDiffTVA(
      //     payDiffTrans?.id!,
      //     payDiffTrans?.vat && payDiffTrans?.vat !== ""
      //       ? `${parseFloat(payDiffTrans?.vat) + parseFloat(data.vat)}`
      //       : data.vat
      //   );
      // }

      if (
        form.relatedToFactureType === "child" &&
        data.id &&
        form.facture_ids!.length > 0
      ) {
        await linkTransactionstoBill(data.id, form.facture_ids!);
      }
      await getTransactions(
        transactionNameSearch && transactionNameSearch !== ""
          ? transactionNameSearch
          : ""
      );
      if (
        company &&
        company.pack &&
        NonImmoPacks.includes(company?.pack! as any)
      ) {
        await checkTransactionsBills(context!);
      }
      restePayDiffForm();
    } catch (error: any) {
      ErrorLogger("transaction creation form", error);
      setLoading(false);
      setPayDiffCreateLoading(false);
      setErrorMessage({
        type: "undexpected_issue",
        message:
          "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.",
      });
    }
  };

  const createTransactionEnc: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    await createTransactionLogic(form, "");
  };

  const createTransactionDec: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    await createTransactionLogic(form, "-");
  };

  const updateParentPayDiffTVA = async (id: string, vat: string) => {
    try {
      await api.post(
        `/api/Transaction/Update`,
        {
          id,
          vat,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
    } catch (error: any) {
      ErrorLogger("parent pay diff transaction tva update", error);
    }
  };

  const restePayDiffForm = () => {
    payDiff_reset({
      label: null,
      amount: null,
      date: null,
      category: null,
      documents: null,
      modalType: null,
      sign: null,
    });
    setErrorMessage(null);
    setDocuments([]);
  };

  const handleDocumentsChange = (event: { target: { files: any } }) => {
    setDocuments((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  // getting the necessary bills (factures) based on the selected the transaction that u will assign it
  // based on sign and amount
  const billSuggestionsChecker = () => {
    // [...billSuggestions].filter((elt) => {
    //   let transactions = elt.transactions;
    //   if (transactions && transactions.length > 0) {
    //     let parentIndex = transactions.findIndex(
    //       (tr) => tr.relatedToFactureType === InvoiceTransactionTypes.Parent
    //     );

    //     if (parentIndex > -1) {
    //       let transaction = payDiffTrans || billTransaction || null;

    //       if (transaction && transaction.id) {
    //         if (transaction.amount > 0) {
    //           if (
    //             transactions[parentIndex].parentType ===
    //               InvoiceTransactionParentTypes.Enc ||
    //             transactions[parentIndex].parentType ===
    //               InvoiceTransactionParentTypes.ManualEnc
    //           ) {
    //             return elt;
    //           } else {
    //             return;
    //           }
    //         }
    //         if (transaction.amount <= 0) {
    //           if (
    //             transactions[parentIndex].parentType ===
    //             InvoiceTransactionParentTypes.Dec
    //           ) {
    //             return elt;
    //           } else {
    //             return;
    //           }
    //         }
    //       }
    //     } else {
    //       return elt;
    //     }
    //   }
    // });
    return [...billSuggestions];
  };

  //tva selection

  const {
    control: tva_control,
    setValue: tva_setValue,
    getValues: tva_getValues,
    watch: tva_watch,
    register: tva_register,
    handleSubmit: tva_handleSubmit,
    reset: tva_reset,
    formState: { errors: tva_errors },
  } = useForm<TVAFormValues>({});
  const { ref: tvaRef, ...tva } = tva_register("tva");
  const { ref: tva_amountRef, ...tva_amount } = tva_register("tva_amount");

  const addTVARow: SubmitHandler<TVAFormValues> = async (
    form: TVAFormValues
  ) => {
    try {
      let row: any = {};

      if (!form.tva_amount || !form.tva) {
        return;
      }

      if (form.tva) {
        row.tva = form.tva;
      }

      if (form.tva_amount) {
        row.tva_amount = form.tva_amount;
      }

      if (!form.id) {
        row.id = Date.now();
      } else {
        row.id = form.id;
      }

      setRows((prevState) => {
        let oldState = [...prevState];

        return [...oldState, row];
      });
      closeRow();
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const closeRow = () => {
    tva_reset({
      tva: null,
      tva_amount: null,
    });
    setAddNewRow(false);
  };

  const resetRow = (row: TVAFormValues) => {
    if (row.id === firstRowId) {
      setUpdatingFirst(true);
    }
    tva_reset({
      ...row,
    });
  };

  const deleteRow = (id: number | string) => {
    setRows((prevState) => {
      const oldState = prevState.filter((row) => row.id !== id);
      return oldState;
    });
    reset();
  };

  const closeTVAModal = () => {
    setTVAModal(false);
    setMixteTVASection(false);
    closeRow();
    setRows([]);
    setTvaUpdateLoading(false);
    setSingleTrans(null);
    setSingleTVAAmount(null);
  };

  const tvaCallback = () => {
    setTVAModal(true);
  };

  const updateTransactionTVA = async (
    id: string,
    amount: number,
    vat: number
  ) => {
    const options: any = {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    toast.dismiss();
    try {
      setTvaUpdateLoading(true);
      toast.warning(
        "Cela peut prendre quelques secondes,s'il vous plaît, ne faites pas d'autre action ...",
        options
      );

      const res = await api.post(
        `/api/Transaction/Update`,
        {
          id,
          vat: !isNaN(vat) ? setTVA(amount, vat / 100) : null,
          vatPercentage: !isNaN(vat) ? vat : null,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans.id!);
      } else {
        if (
          res &&
          res.data &&
          res.data.updated &&
          res.data.updated[0] &&
          res.data.updated[0].id === id
        ) {
          implicitStateChange(res.data.updated[0].view, res.data.updated[0].id);
        }
      }

      toast.success("Votre mise à jour a été effectuée avec succès", options);
      closeTVAModal();
    } catch (error: any) {
      setTvaUpdateLoading(false);
      toast.error(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        options
      );
      ErrorLogger("updateTransactionTVA : ", error);
    }
  };

  // updating the splitted transactions (when u chose the trabsaction tva to be mixed so we split it to multiple smaller transactions
  // with different tva amounts)
  const updateSplittedTransactionTVA = async (id: string, amount: number) => {
    const options: any = {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    toast.dismiss();
    try {
      let rowsAmount = rows.reduce(
        (acc, curr: TVAFormValues) => acc + Math.abs(curr.tva_amount!),
        0
      );
      let rowsTVAAmount = rows.reduce(
        (acc, curr: TVAFormValues) =>
          acc + setTVA(curr.tva_amount!, curr.tva?.value! / 100),
        0
      );

      if (amount < 0) {
        rowsTVAAmount = -rowsTVAAmount;
      }

      if (
        parseFloat(Math.abs(parseFloat(String(rowsAmount))).toFixed(2)) !==
        parseFloat(Math.abs(parseFloat(String(amount))).toFixed(2))
      ) {
        setTvaUpdateLoading(false);
        toast.error(
          "la somme des montants que vous avez saisis est différente du montant de la transaction, veuillez vérifier!",
          options
        );
        return;
      }

      setTvaUpdateLoading(true);
      toast.warning(
        "Cela peut prendre quelques secondes,s'il vous plaît, ne faites pas d'autre action ...",
        options
      );

      const res = await api.post(
        `/api/Transaction/Update`,
        {
          id,
          vat:
            !isNaN(rowsTVAAmount) && rowsTVAAmount !== 0 ? rowsTVAAmount : null,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (
        !payDiffTrans &&
        res &&
        res.data &&
        res.data.updated &&
        res.data.updated[0] &&
        res.data.updated[0].id === id
      ) {
        implicitStateChange(res.data.updated[0].view, res.data.updated[0].id);
      }

      toast.success("Votre mise à jour a été effectuée avec succès", options);
      let tvaRows = rows;
      let parentId = id;
      closeTVAModal();

      for (let row of tvaRows) {
        await api.post(
          `/api/SplittedTransaction/Create`,
          {
            amount: row.tva_amount,
            transaction_id: parentId,
            vatAmount: row.tva?.value,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      if (payDiffTrans && payDiffTrans.id) {
        await getPayDiffTransactions(payDiffTrans.id!);
      } else {
        const parentTrasnaction = await getSingleTransaction(parentId);
        if (parentTrasnaction && parentTrasnaction.id === parentId) {
          implicitStateChange(parentTrasnaction, parentTrasnaction.id);
        }
      }
    } catch (error: any) {
      setTvaUpdateLoading(false);
      toast.error(
        "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
        options
      );
      ErrorLogger("updateSplittedTransactionTVA : ", error);
    }
  };

  const cleanSplittedTransactionTVA = async (id: string) => {
    try {
      await api.post(
        `/api/SplittedTransaction/deleteWhere`,
        {
          where: {
            transaction_id: [id],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
    } catch (error: any) {
      ErrorLogger("updateSplittedTransactionTVA : ", error);
    }
  };

  return (
    <>
      <div className="toValide-transactions-table">
        <DataTable
          columns={columns}
          data={transactionsToValid}
          noDataComponent={<p>Il n'y a aucun data à afficher</p>}
          pagination
          paginationComponent={(props) => {
            const customProps = { ...props, color: "primary" };
            return <BootyPagination {...customProps} />;
          }}
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          paginationDefaultPage={currentPage}
          paginationPerPage={20}
          progressPending={pending}
          progressComponent={
            <>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
            </>
          }
        />
        <div className="openbtn text-center">
          {/*
        transaction deletion modal 
        */}
          <Modal
            isOpen={open}
            toggle={() => {
              setTransactionId(null);
              setOpen(false);
            }}
            className="modal-danger modal-dialog-centered"
          >
            <ModalHeader
              toggle={() => {
                setTransactionId(null);
                setOpen(false);
              }}
            >
              Supprimer une transaction
            </ModalHeader>
            <ModalBody>
              <div className="content-text p-lg-5">
                <p className="msg-text">
                  Vous êtes sur de vouloir supprimer la transaction{" "}
                  {
                    transactionsToValid.find((elt) => elt.id === transactionId)
                      ?.label
                  }
                  ?
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                outline
                onClick={() => {
                  setTransactionId(null);
                  setOpen(false);
                }}
              >
                Non
              </Button>
              <Button color="danger" onClick={() => deleteTransaction()}>
                Oui
              </Button>
            </ModalFooter>
          </Modal>

          {/*
         stop archive modal 
        */}
          <Modal
            isOpen={stopArchiveModal}
            toggle={() => {
              setStopArchiveModal(false);
            }}
            className="modal-warning modal-dialog-centered"
          >
            <ModalHeader
              toggle={() => {
                setStopArchiveModal(false);
              }}
            >
              Transaction non catégorisée
            </ModalHeader>
            <ModalBody>
              <div className="content-text p-lg-5">
                <p className="msg-text">
                  On ne peut pas archiver une transaction non catégorisée
                </p>
              </div>
            </ModalBody>
          </Modal>

          {/*
        transaction rule modal 
        */}
          <Modal
            isOpen={isImmobModal}
            toggle={async () => {
              await categCallback();
              setTransactionId(null);
              setIsImmobModal(false);
            }}
            className="modal-warning modal-dialog-centered"
          >
            <ModalHeader
              toggle={async () => {
                await categCallback();
                setTransactionId(null);
                setIsImmobModal(false);
              }}
            >
              Vérification de la catégorisation
            </ModalHeader>
            <ModalBody>
              <div className="content-text p-lg-5">
                <p className="msg-text">
                  Cette transaction est-elle une immobilisation ?
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="warning"
                outline
                onClick={async () => {
                  await categCallback();
                  setTransactionId(null);
                  setIsImmobModal(false);
                }}
              >
                Non
              </Button>
              <Button color="warning" onClick={async () => await immobChange()}>
                Oui
              </Button>
            </ModalFooter>
          </Modal>

          {/*
        transaction carburant modal 
        */}
          <Modal
            isOpen={isCarbModal}
            toggle={() => {
              setSingleCarb(null);
              setIsCarbModal(false);
            }}
            className="modal-warning modal-dialog-centered"
          >
            <ModalHeader
              toggle={() => {
                setSingleCarb(null);
                setIsCarbModal(false);
              }}
            >
              Quel est le type de véhicule ?
            </ModalHeader>
            <ModalBody>
              <div className="content-text p-lg-5 carb-modal">
                <div className="content-text carb-modal-wrapper">
                  <div className="card util-veh-card">
                    <img src={UtilVeh} alt="Véhicule utilitaire" />
                    <Button
                      onClick={async () =>
                        await editCarbTransaction(CarbVehTypes.Utility)
                      }
                      color="secondary"
                      disabled={carbLoading}
                    >
                      Véhicule utilitaire
                    </Button>
                  </div>
                  <div className="card tour-veh-card">
                    <img src={TourVeh} alt="Véhicule de tourisme" />
                    <Button
                      onClick={async () =>
                        await editCarbTransaction(CarbVehTypes.Tourisme)
                      }
                      disabled={carbLoading}
                      color="primary"
                    >
                      Véhicule de tourisme
                    </Button>
                  </div>
                </div>

                <div className="carb-modal-nb">
                  <div className="icon-col">
                    <FiInfo />
                  </div>
                  <div className="text-col">
                    NB: 80% du montant de la TVA sera déductible pour les
                    véhicules de tourisme et 100% pour les véhicules
                    utilitaires.
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/*
        transaction archive no file 
        */}
          <Modal
            isOpen={noFilesModal}
            toggle={() => {
              setNoFilesModal(false);
              setNoFilesTrans(null);
              setNoFilesLoading(false);
            }}
            className="modal-warning modal-dialog-centered"
          >
            <ModalHeader
              toggle={() => {
                setNoFilesModal(false);
                setNoFilesTrans(null);
                setNoFilesLoading(false);
              }}
            >
              Vérification
            </ModalHeader>
            <ModalBody>
              <div className="content-text p-lg-5">
                <p className="msg-text">
                  {(noFilesTrans?.sub_category.subType === "Charges" ||
                    noFilesTrans?.sub_category.subType ===
                      "Chiffre d'affaires") &&
                  noFilesTrans?.sub_category.key !== IndemCategKeys.OUT102
                    ? "Un justificatif doit être fourni pour ce type d’opérations. En cas de contrôle, vous devrez être en mesure de fournir toutes les pièces nécessaires."
                    : "Cette transaction n'a pas de justificatif, voulez-vous continuer ?"}
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="warning"
                outline
                disabled={noFilesLoading}
                onClick={() => {
                  setNoFilesModal(false);
                  setNoFilesTrans(null);
                  setNoFilesLoading(false);
                }}
              >
                Annuler
              </Button>
              <Button
                color="warning"
                disabled={noFilesLoading}
                onClick={async () => {
                  try {
                    setNoFilesLoading(true);
                    await archiveTransaction(noFilesTrans?.id!);
                    setNoFilesModal(false);
                    setNoFilesTrans(null);
                    setNoFilesLoading(false);
                  } catch (error) {
                    console.error(error);
                    setNoFilesModal(false);
                    setNoFilesTrans(null);
                    setNoFilesLoading(false);
                  }
                }}
              >
                {(noFilesTrans?.sub_category.subType === "Charges" ||
                  noFilesTrans?.sub_category.subType ===
                    "Chiffre d'affaires") &&
                noFilesTrans?.sub_category.key !== IndemCategKeys.OUT102
                  ? "J’ai compris"
                  : "Oui"}
              </Button>
            </ModalFooter>
          </Modal>

          {/*
        décaissements / encaissements modal 
        */}
          <Modal
            className="modal-primary modal-dialog-centered modal-lg"
            isOpen={openCategModal}
            toggle={async () => {
              await categCallback();
            }}
          >
            <ModalHeader
              toggle={async () => {
                await categCallback();
              }}
            >
              {categStatus && categStatus === "dec"
                ? "Décaissements"
                : "Encaissements"}
            </ModalHeader>
            <ModalBody>
              <div
                className={`content-form-block ${
                  categStatus && categStatus === "dec"
                    ? "decaissements-block"
                    : ""
                }`}
              >
                <div className="top-chois-form">
                  <Row>
                    <Col md={6} sm={12} className="left-label">
                      <Label className="label-primary">
                        Est-ce un remboursement ?
                      </Label>
                    </Col>
                    <Col md={6} sm={12}>
                      <div className="form-line">
                        <div className="check-box check-primary">
                          <input
                            type="radio"
                            id="test2"
                            name="radio-group"
                            onClick={() => setCategremb(true)}
                          />
                          <label htmlFor="test2">Oui</label>
                        </div>
                        <div className="check-box check-primary">
                          <input
                            type="radio"
                            id="test3"
                            name="radio-group"
                            onClick={() => setCategremb(false)}
                            defaultChecked={true}
                          />
                          <label htmlFor="test3">Non</label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <FormGroup className="form-icon icon-end">
                  <Input
                    value={searchCategName || ""}
                    type="text"
                    className="form-default"
                    onChange={(event) => {
                      setSearchCategName(event.target.value);
                    }}
                  />
                  <button
                    className="icon icon-primary"
                    onClick={async () => {
                      if (closeCategSearch) {
                        await getCategories();
                        setCloseCategSearch(false);
                        setSearchCategName("");
                      }
                    }}
                  >
                    {closeCategSearch ? <RiCloseLine /> : <RiSearchLine />}
                  </button>
                </FormGroup>
                {/* </form> */}
                {!categRemb ? (
                  <>
                    {decCategs.length > 0 && encCategs.length > 0 ? (
                      <>
                        {categStatus === "dec" ? (
                          <Decaissements
                            categs={decCategs}
                            transaction={singleTrans}
                            callback={async () => await categCallback()}
                            immobCheckCallback={immobCheckCallback}
                            carbCheckCallback={carbCheckCallback}
                            diffPayCheckCallback={diffPayCheckCallback}
                            setRefreshCategs={setRefreshCategs}
                            tvaCallback={tvaCallback}
                          />
                        ) : (
                          <Encaissements
                            categs={encCategs}
                            transaction={singleTrans}
                            callback={async () => await categCallback()}
                            setRefreshCategs={setRefreshCategs}
                            diffPayCheckCallback={diffPayCheckCallback}
                            tvaCallback={tvaCallback}
                          />
                        )}
                      </>
                    ) : (
                      <Spinner color="success" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    )}
                  </>
                ) : (
                  <>
                    {decCategs.length > 0 && encCategs.length > 0 ? (
                      <>
                        {categStatus === "enc" ? (
                          <Decaissements
                            categs={decCategs}
                            transaction={singleTrans}
                            callback={async () => await categCallback()}
                            immobCheckCallback={immobCheckCallback}
                            carbCheckCallback={carbCheckCallback}
                            diffPayCheckCallback={diffPayCheckCallback}
                            setRefreshCategs={setRefreshCategs}
                            tvaCallback={tvaCallback}
                          />
                        ) : (
                          <Encaissements
                            categs={encCategs}
                            transaction={singleTrans}
                            callback={async () => await categCallback()}
                            setRefreshCategs={setRefreshCategs}
                            diffPayCheckCallback={diffPayCheckCallback}
                            tvaCallback={tvaCallback}
                          />
                        )}
                      </>
                    ) : (
                      <Spinner color="success" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    )}
                  </>
                )}
              </div>
            </ModalBody>
          </Modal>

          {/*
        add immob 
        */}
          <Modal
            className="modal-secondary modal-dialog-centered "
            isOpen={immbModal}
            toggle={() => {
              setImmob(null);
              immobReset();
              setErrorMessage(null);
              setImmobModal(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setImmob(null);
                immobReset();
                setErrorMessage(null);
                setImmobModal(false);
              }}
            >
              Immobilisation
            </ModalHeader>
            <form onSubmit={immobHandleSubmit(editImmobTransaction)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Type d’immobilisation</Label>
                        <Controller
                          name="type"
                          control={immobControl}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={
                                decCategs.find(
                                  (elt) => elt.name === "Immobilisations"
                                )
                                  ? decCategs
                                      .find(
                                        (elt) => elt.name === "Immobilisations"
                                      )
                                      ?.sub_categories?.reduce(
                                        (acc, curr) => [
                                          ...acc,
                                          {
                                            value: curr.id,
                                            label: `${curr.name} ${
                                              !!curr?.yearsRange?.length
                                                ? curr.yearsRange?.length > 1
                                                  ? `(${curr.yearsRange[0]} à ${curr.yearsRange[1]} ans)`
                                                  : `(${curr.yearsRange[0]} ans)`
                                                : ""
                                            }`,
                                          },
                                        ],
                                        [] as OptionType[]
                                      )
                                  : ASSETS_OPTIONS
                              }
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <div
                        className="asset-type-info"
                        onClick={() => {
                          setImmobMoreInfo(true);
                        }}
                      >
                        <RiErrorWarningLine />
                        <span>
                          Plus d'informations sur les types d'immobilisation
                        </span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="numberOfYears">
                          Nombre d'années (durée d'utilisation)
                        </Label>
                        <Input
                          id="label"
                          innerRef={numberOfYearsRef}
                          {...numberOfYears}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                          min={0}
                        />
                        <span className="icon icon-secondary ">
                          <RiTimeFill />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="transactionAmount">
                          Le montant de l’immobilisation
                        </Label>
                        <Input
                          id="transactionAmount"
                          innerRef={transactionAmountRef}
                          {...transactionAmount}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                          step={0.01}
                          // min={0}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={MoneySacIconBlue} />}
                          {/* <img src={MoneySacIconBlue} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="transactionDate">Date d'aquisition</Label>
                        <Controller
                          control={immobControl}
                          name="transactionDate"
                          render={({ field }) => (
                            <DatePicker
                              placeholderText="Date de début"
                              onChange={(date: any) => {
                                field.onChange(date);
                                setErrorMessage(null);
                              }}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-secondary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={CalenderIconBlue} />}
                          {/* <img src={CalenderIconBlue} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    {errorMessage?.type === "invalid_immob_form" && (
                      <Col md={12}>
                        <Alert color="danger">{errorMessage?.message}</Alert>
                      </Col>
                    )}
                  </Row>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    setImmob(null);
                    immobReset();
                    setErrorMessage(null);
                    setImmobModal(false);
                  }}
                  type="button"
                >
                  Annuler
                </Button>
                <Button color="secondary" type="submit">
                  Valider
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*
        add emprunt data
        */}
          <Modal
            className="modal-secondary modal-dialog-centered "
            isOpen={empruntModal}
            toggle={() => {
              setSingleEmprunt(null);
              empruntReset();
              setErrorMessage(null);
              setEmpruntModal(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setSingleEmprunt(null);
                empruntReset();
                setErrorMessage(null);
                setEmpruntModal(false);
              }}
            >
              Emprunt
            </ModalHeader>
            <form onSubmit={empruntHandleSubmit(editEmpruntTransaction)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={12}>
                      <div
                        className="asset-type-info"
                        onClick={() => {
                          setempruntMoreInfo(true);
                        }}
                      >
                        <RiErrorWarningLine />
                        <span>Plus d'informations sur votre Emprunt</span>
                      </div>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="capitalAmount">Capital remboursé</Label>
                        <Input
                          id="label"
                          innerRef={capitalAmountRef}
                          {...capitalAmount}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          step={0.01}
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                          min={0}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={MoneySacIconBlue} />}
                          {/* <img src={MoneySacIconBlue} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="chargeAmount">Intérêts</Label>
                        <Input
                          id="chargeAmount"
                          innerRef={chargeAmountRef}
                          {...chargeAmount}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                          step={0.01}
                          min={0}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={MoneySacIconBlue} />}
                          {/* <img src={MoneySacIconBlue} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="insuranceAmount">Assurances</Label>
                        <Input
                          id="insuranceAmount"
                          innerRef={insuranceAmountRef}
                          {...insuranceAmount}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-secondary"
                          onChange={() => setErrorMessage(null)}
                          step={0.01}
                          min={0}
                        />
                        <span className="icon icon-secondary ">
                          {<ReactSVG src={MoneySacIconBlue} />}
                          {/* <img src={MoneySacIconBlue} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>

                    {errorMessage?.type === "invalid_emprunt_form" && (
                      <Col md={12}>
                        <Alert color="danger">{errorMessage?.message}</Alert>
                      </Col>
                    )}
                  </Row>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    setSingleEmprunt(null);
                    empruntReset();
                    setErrorMessage(null);
                    setEmpruntModal(false);
                  }}
                  type="button"
                >
                  Annuler
                </Button>
                <Button color="secondary" type="submit">
                  Valider
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*
        add diff pay data
        */}
          <Modal
            className="modal-primary modal-dialog-centered pay-diff-modal"
            isOpen={diffPayModal}
            toggle={async () => {
              setPayDiffTrans(null);
              setPayDiffSubCateg(null);
              setErrorMessage(null);
              setDiffPayModal(false);
              payDiffStateHandler?.setIsPayDiffSubCateg(null);
              payDiffStateHandler?.setIsPayDiffTrans(null);
              payDiffStateHandler?.setIsPayDiffFire(false);
              if (
                payDiffTransArray.length > 0 &&
                Math.abs(
                  parseFloat(
                    payDiffTransArray
                      .reduce(
                        (acc, curr) =>
                          parseFloat(String(acc)) +
                          parseFloat(String(curr.amount)),
                        0
                      )
                      .toFixed(2)
                  )
                ) !==
                  Math.abs(
                    parseFloat(
                      parseFloat(String(payDiffTrans?.amount)).toFixed(2)
                    )
                  )
              ) {
                await deletePayDiffTransaction(payDiffTrans?.id!);
              }
              await removeTransactionDiffCateg(payDiffTrans!);
            }}
          >
            <ModalHeader
              toggle={async () => {
                setPayDiffTrans(null);
                setPayDiffSubCateg(null);
                setErrorMessage(null);
                setDiffPayModal(false);
                setPAyDiffTransArray([]);
                payDiffStateHandler?.setIsPayDiffSubCateg(null);
                payDiffStateHandler?.setIsPayDiffTrans(null);
                payDiffStateHandler?.setIsPayDiffFire(false);
                if (
                  payDiffTransArray.length > 0 &&
                  Math.abs(
                    parseFloat(
                      payDiffTransArray
                        .reduce(
                          (acc, curr) =>
                            parseFloat(String(acc)) +
                            parseFloat(String(curr.amount)),
                          0
                        )
                        .toFixed(2)
                    )
                  ) !==
                    Math.abs(
                      parseFloat(
                        parseFloat(String(payDiffTrans?.amount)).toFixed(2)
                      )
                    )
                ) {
                  await deletePayDiffTransaction(payDiffTrans?.id!);
                }
                await removeTransactionDiffCateg(payDiffTrans!);
              }}
            >
              {payDiffSubCateg && payDiffSubCateg.name
                ? payDiffSubCateg.name
                : payDiffTrans?.amount! > 0
                ? "Encaissements différés"
                : "Paiements différés"}
            </ModalHeader>
            <ModalBody>
              <div className="content-form-block">
                <Row className="text-center">
                  <div>
                    <h5>
                      Le montant de votre transaction {payDiffTrans?.label} :{" "}
                      <strong>{payDiffTrans?.amount}</strong> €
                    </h5>
                  </div>
                </Row>
                <Row className="mt-3">
                  <DataTable
                    columns={diffPayColumns}
                    data={payDiffTransArray}
                    noDataComponent={<p>Il n'y a pas encore de transactions</p>}
                    pagination
                    paginationComponent={(props) => {
                      const customProps = { ...props, color: "primary" };
                      return <BootyPagination {...customProps} />;
                    }}
                    persistTableHead={true}
                  />
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={async () => {
                  setPayDiffTrans(null);
                  setPayDiffSubCateg(null);
                  setErrorMessage(null);
                  setDiffPayModal(false);
                  setPAyDiffTransArray([]);
                  payDiffStateHandler?.setIsPayDiffSubCateg(null);
                  payDiffStateHandler?.setIsPayDiffTrans(null);
                  payDiffStateHandler?.setIsPayDiffFire(false);
                  if (
                    payDiffTransArray.length > 0 &&
                    Math.abs(
                      parseFloat(
                        payDiffTransArray
                          .reduce(
                            (acc, curr) =>
                              parseFloat(String(acc)) +
                              parseFloat(String(curr.amount)),
                            0
                          )
                          .toFixed(2)
                      )
                    ) !==
                      Math.abs(
                        parseFloat(
                          parseFloat(String(payDiffTrans?.amount)).toFixed(2)
                        )
                      )
                  ) {
                    await deletePayDiffTransaction(payDiffTrans?.id!);
                  }
                  await removeTransactionDiffCateg(payDiffTrans!);
                }}
                type="button"
              >
                Annuler
              </Button>
              {payDiffTransArray.length > 0 &&
              Math.abs(
                parseFloat(
                  payDiffTransArray
                    .reduce(
                      (acc, curr) =>
                        parseFloat(String(acc)) +
                        parseFloat(String(curr.amount)),
                      0
                    )
                    .toFixed(2)
                )
              ) ===
                Math.abs(
                  parseFloat(
                    parseFloat(String(payDiffTrans?.amount)).toFixed(2)
                  )
                ) ? (
                <Button
                  color="primary"
                  type="button"
                  onClick={async () => {
                    setPayDiffTrans(null);
                    setPayDiffSubCateg(null);
                    setErrorMessage(null);
                    setDiffPayModal(false);
                    setPAyDiffTransArray([]);
                    payDiffStateHandler?.setIsPayDiffSubCateg(null);
                    payDiffStateHandler?.setIsPayDiffTrans(null);
                    payDiffStateHandler?.setIsPayDiffFire(false);
                    await getTransactions();
                    // implicitStateChange(payDiffTrans!, payDiffTrans?.id!);
                  }}
                >
                  Terminé
                </Button>
              ) : (
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    if (
                      (payDiffTrans?.amount! > 0 &&
                        payDiffSubCateg?.type.toLowerCase() === "output") ||
                      (payDiffTrans?.amount! < 0 &&
                        payDiffSubCateg?.type.toLowerCase() === "output")
                    ) {
                      setAddNoteFraisModal(true);
                    }

                    if (
                      (payDiffTrans?.amount! > 0 &&
                        payDiffSubCateg?.type.toLowerCase() === "input") ||
                      (payDiffTrans?.amount! < 0 &&
                        payDiffSubCateg?.type.toLowerCase() === "input")
                    ) {
                      setAddEncModal(true);
                    }
                  }}
                >
                  Ajouter une transaction
                </Button>
              )}
            </ModalFooter>
          </Modal>

          {/*
        validate diff pay data
        */}
          <Modal
            className="modal-primary modal-dialog-centered pay-diff-modal"
            isOpen={diffPayValidateModal}
            toggle={async () => {
              setPayDiffTrans(null);
              setPayDiffSubCateg(null);
              setErrorMessage(null);
              setDiffPayValidateModal(false);
              setPayDiffValidation(false);
              setPayDiffNeddValidation({});
            }}
          >
            <ModalHeader
              toggle={async () => {
                setPayDiffTrans(null);
                setPayDiffSubCateg(null);
                setErrorMessage(null);
                setDiffPayValidateModal(false);
                setPayDiffValidation(false);
                setPayDiffNeddValidation({});
              }}
            >
              Validation des{" "}
              {payDiffTrans?.amount! > 0 ? "encaissements" : "paiements"}{" "}
              différés
            </ModalHeader>

            <ModalBody>
              <div className="content-form-block">
                <Row className="text-center">
                  <div>
                    {!payDiffTransArray.every((elt) => elt.subCategoryId) ? (
                      <h5>
                        Vous devez catégoriser toutes les transactions afin de
                        les valider
                      </h5>
                    ) : (
                      <h5>
                        Vous allez valider la transaction {payDiffTrans?.label}{" "}
                        : <strong>{payDiffTrans?.amount}</strong> € et chaque
                        transaction qui lui est associée
                      </h5>
                    )}
                  </div>
                </Row>
                <Row className="mt-3">
                  <DataTable
                    columns={diffPayColumns}
                    data={payDiffTransArray}
                    noDataComponent={<p>Il n'y a pas encore de transactions</p>}
                    pagination
                    paginationComponent={(props) => {
                      const customProps = { ...props, color: "primary" };
                      return <BootyPagination {...customProps} />;
                    }}
                  />
                </Row>
                <Row className="text-center">
                  <div>
                    {errorMessage?.type === "payDiff_need_validation" && (
                      <span className="text-center text-danger">
                        {errorMessage?.message}
                      </span>
                    )}
                  </div>
                </Row>
              </div>
            </ModalBody>
            {payDiffTransArray.every((elt) => elt.subCategoryId) && (
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={async () => {
                    setPayDiffTrans(null);
                    setPayDiffSubCateg(null);
                    setErrorMessage(null);
                    setDiffPayValidateModal(false);
                    setPayDiffValidation(false);
                    setPayDiffNeddValidation({});
                  }}
                  type="button"
                  disabled={payDiffValidation}
                >
                  Annuler
                </Button>
                {payDiffTransArray.length > 0 &&
                Math.abs(
                  parseFloat(
                    payDiffTransArray
                      .reduce(
                        (acc, curr) =>
                          parseFloat(String(acc)) +
                          parseFloat(String(curr.amount)),
                        0
                      )
                      .toFixed(2)
                  )
                ) ===
                  Math.abs(
                    parseFloat(
                      parseFloat(String(payDiffTrans?.amount)).toFixed(2)
                    )
                  ) ? (
                  <Button
                    color="primary"
                    type="button"
                    disabled={payDiffValidation}
                    onClick={async () => {
                      await validatePayDiffTransactions();
                    }}
                  >
                    {payDiffValidation ? (
                      <Spinner color="light" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    ) : (
                      "Valider"
                    )}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    type="button"
                    onClick={() =>
                      payDiffTrans?.amount! > 0
                        ? setAddEncModal(true)
                        : setAddNoteFraisModal(true)
                    }
                  >
                    Ajouter une transaction
                  </Button>
                )}
              </ModalFooter>
            )}
          </Modal>

          {/*
        archive diff pay data
        */}
          <Modal
            className="modal-primary modal-dialog-centered pay-diff-modal"
            isOpen={diffPayArchiveModal}
            toggle={async () => {
              setPayDiffTrans(null);
              setPayDiffSubCateg(null);
              setErrorMessage(null);
              setDiffPayArchiveModal(false);
              setPayDiffArchive(false);
              setPayDiffNeddValidation({});
            }}
          >
            <ModalHeader
              toggle={async () => {
                setPayDiffTrans(null);
                setPayDiffSubCateg(null);
                setErrorMessage(null);
                setDiffPayArchiveModal(false);
                setPayDiffArchive(false);
                setPayDiffNeddValidation({});
              }}
            >
              Archiver des{" "}
              {payDiffTrans && payDiffTrans.amount > 0
                ? "encaissements"
                : "paiements"}{" "}
              différés
            </ModalHeader>
            <ModalBody>
              <div className="content-form-block">
                <Row className="text-center">
                  <div>
                    {!payDiffTransArray.every((elt) => elt.subCategoryId) ? (
                      <h5>
                        Vous devez catégoriser toutes les transactions afin de
                        les archiver
                      </h5>
                    ) : (
                      <>
                        {!payDiffTransArray.every(
                          (elt) => elt.documents && elt.documents.length > 0
                        ) ? (
                          <h5>
                            Certaines transactions n'ont pas de justificatifs,
                            voulez-vous continuer ?
                          </h5>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </Row>
                <Row className="mt-3">
                  <DataTable
                    columns={diffPayColumns}
                    data={payDiffTransArray}
                    noDataComponent={<p>Il n'y a pas encore de transactions</p>}
                    pagination
                    paginationComponent={(props) => {
                      const customProps = { ...props, color: "primary" };
                      return <BootyPagination {...customProps} />;
                    }}
                  />
                </Row>
              </div>
            </ModalBody>
            {payDiffTransArray.every((elt) => elt.subCategoryId) && (
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={async () => {
                    setPayDiffTrans(null);
                    setPayDiffSubCateg(null);
                    setErrorMessage(null);
                    setDiffPayArchiveModal(false);
                    setPayDiffArchive(false);
                    setPayDiffNeddValidation({});
                  }}
                  type="button"
                  disabled={payDiffArchive}
                >
                  Annuler
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={payDiffArchive}
                  onClick={async () => {
                    await archiveTransaction(payDiffTrans?.id!);
                    setPayDiffTrans(null);
                    setPayDiffSubCateg(null);
                    setErrorMessage(null);
                    setDiffPayArchiveModal(false);
                    setPayDiffArchive(false);
                    setPayDiffNeddValidation({});
                  }}
                >
                  {payDiffArchive ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Archiver"
                  )}
                </Button>
              </ModalFooter>
            )}
          </Modal>

          {/*
        more info immob
        */}
          <Modal
            className="modal-secondary modal-dialog-centered "
            isOpen={immobMoreInfo}
            toggle={() => {
              setImmobMoreInfo(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setImmobMoreInfo(false);
              }}
            >
              Type d’immobilisation et durée d’amortissement
            </ModalHeader>

            <ModalBody>
              <div className="content-form-block">
                <div className="container">
                  <div className="section-information-societe">
                    <h3>Durée préconisée par le plan comptable général</h3>
                    <ul>
                      <li>Bâtiments commerciaux (20 à 50 ans)</li>
                      <li>Bâtiments industriels (20 ans)</li>
                      <li>Bureaux 25 ans</li>
                      <li>Immeubles d'habitation (40 à 100 ans)</li>
                      <li>Entrepôts (20 ans)</li>
                      <li>Maçonnerie (15 ans)</li>
                      <li>Agencements de bureaux (10 ans)</li>
                      <li>Agencement léger (5 à 6,5 ans)</li>
                      <li>Peintures, papiers-peints (3 à 5 ans)</li>
                      <li>Revêtements de sols (5 ans)</li>
                      <li>Mobilier de magasin (10 ans)</li>
                      <li>Gros travaux dans locaux (5 à 10 ans)</li>
                      <li>Matériel et outillage</li>
                      <ul>
                        <li>Matériel (6,5 à 10 ans)</li>
                        <li>Outillage (5 à 10 ans)</li>
                      </ul>
                      <li>Matériel de bureau</li>
                      <ul>
                        <li>Mobilier (10 ans)</li>
                        <li>Photocopieur (5 ans)</li>
                        <li>Matériel électrique (6,5 à 10 ans)</li>
                        <li>Téléphone, répondeur (3 ans 3 ans)</li>
                        <li>Magnétoscope 5 ans</li>
                      </ul>
                      <li>Informatique</li>
                      <ul>
                        <li>Ordinateur (3 à 5 ans 3 à 5 ans)</li>
                        <li>Logiciels (1 à 3 ans)</li>
                        <li>Automobiles (4 à 5 ans)</li>
                      </ul>
                      <li>Biens incorporels</li>
                      <ul>
                        <li>Brevets (5 ans)</li>
                        <li>Dessins durée d'exploitation</li>
                        <li>Autres durées des avantages procurés</li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/*
        more info emprunt
        */}
          <Modal
            className="modal-secondary modal-dialog-centered "
            isOpen={empruntMoreInfo}
            toggle={() => {
              setempruntMoreInfo(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setempruntMoreInfo(false);
              }}
            >
              Emprunt
            </ModalHeader>

            <ModalBody>
              <div className="content-form-block">
                <div className="container">
                  <div className="section-information-societe">
                    <p>
                      Les informations requises se trouvent dans votre tableau
                      de remboursement fourni par l’établissement bancaire
                      auprès duquel vous avez contracté votre emprunt
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/*
        bill suggestion for transaction
        */}
          <Modal
            className="modal-primary modal-dialog-centered "
            isOpen={billSuggestModal}
            toggle={async () => {
              setBillSuggestModal(false);
              setBillTransaction(null);
              setBillAddCustom(false);
              handleBillPageChange(1);
              setFactureNameSearch("");
              await singleTransactionStateInsertion(billTransaction?.id!);
            }}
          >
            <ModalHeader
              toggle={async () => {
                setBillSuggestModal(false);
                setBillTransaction(null);
                setBillAddCustom(false);
                handleBillPageChange(1);
                setFactureNameSearch("");
                await singleTransactionStateInsertion(billTransaction?.id!);
              }}
            >
              Justificatif
            </ModalHeader>

            <ModalBody>
              <div className="content-form-block">
                <div className="form-icon icon-end w-100 mb-2">
                  <Input
                    id="mc"
                    placeholder="Chercher une facture"
                    type="text"
                    className="form-primary"
                    onChange={(e) => setFactureNameSearch(e.target.value)}
                    value={factureNameSearch}
                  />
                  <button
                    type="submit"
                    className="icon icon-primary "
                    onClick={() => setFactureNameSearch("")}
                  >
                    {factureNameSearch !== "" ? <RiCloseLine /> : <ZoomIcon />}
                  </button>
                </div>
                {/* {[...billSuggestions].length > 0 && ( */}
                <>
                  <DataTable
                    columns={billColumns}
                    data={[...billSuggestions].slice(
                      10 * (currentBillPage - 1),
                      10 * currentBillPage
                    )}
                    pagination
                    paginationComponent={(props) => {
                      const customProps = { ...props, color: "primary" };
                      return <BootyPagination {...customProps} />;
                    }}
                    paginationServer
                    // paginationTotalRows={totalBillRows}
                    paginationTotalRows={[...billSuggestions].length}
                    onChangePage={handleBillPageChange}
                    paginationDefaultPage={currentBillPage}
                    paginationPerPage={10}
                    noDataComponent={<p>Il n'y a aucune facture à afficher</p>}
                  />
                </>
                {/* )} */}
                <>
                  <div className="text-center">
                    {[...billSuggestions].length > 0 ? "Ou vous " : "Vous "}
                    pouvez choisir un fichier en cliquant ici.
                  </div>
                  <>
                    <FormGroup>
                      <div className="form-icon icon-start form-file file-primary mt-4">
                        <span className="label-file">
                          Déposez le fichier ici
                        </span>
                        <label className="file-box ">
                          {billAddCustom ? (
                            <Spinner color="success" type="border" size={"sm"}>
                              Loading...
                            </Spinner>
                          ) : (
                            <>
                              <RiDownload2Fill />
                              <Input
                                id="document"
                                className="form-default"
                                type="file"
                                onChange={async (e) => {
                                  const options: any = {
                                    position: "bottom-right",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  };
                                  toast.dismiss();
                                  try {
                                    toast.warning(
                                      "Cela peut prendre quelques secondes, veuillez patienter...",
                                      options
                                    );

                                    setBillAddCustom(true);
                                    const formData = new FormData();
                                    formData.append("id", billTransaction?.id!);
                                    if (
                                      e.target.files &&
                                      e.target.files.length > 0
                                    ) {
                                      for (
                                        let index = 0;
                                        index < e.target.files.length;
                                        index++
                                      ) {
                                        const element = e.target.files[index];
                                        formData.append(
                                          "documents",
                                          element,
                                          element.name
                                        );
                                      }
                                    }

                                    const { data } = await api.post(
                                      `/api/Transaction/Update`,
                                      formData,
                                      {
                                        headers: {
                                          "x-access-token": creds.token,
                                        },
                                      }
                                    );
                                    implicitStateChange(
                                      data.updated[0].view,
                                      data.updated[0].id
                                    );
                                    // await getTransactions();
                                    if (payDiffTrans && payDiffTrans.id) {
                                      await getPayDiffTransactions(
                                        payDiffTrans?.id!
                                      );
                                    }
                                    if (
                                      company &&
                                      company.pack &&
                                      NonImmoPacks.includes(
                                        company?.pack! as any
                                      )
                                    ) {
                                      await checkTransactionsBills(context!);
                                    }

                                    setBillAddCustom(false);
                                    setBillSuggestModal(false);
                                    setBillTransaction(null);
                                    toast.success(
                                      "Votre mise à jour a été effectuée avec succès bill",
                                      options
                                    );
                                  } catch (error: any) {
                                    ErrorLogger(
                                      "updating a transaction's document",
                                      error
                                    );

                                    toast.error(
                                      "Quelque chose d'inattendu s'est produit, veuillez réessayer plus tard.",
                                      options
                                    );
                                  }
                                  setBillAddCustom(false);
                                }}
                                multiple
                              />
                              <span>Télécharger d'ici</span>
                            </>
                          )}
                        </label>
                      </div>
                    </FormGroup>
                  </>
                </>
              </div>
            </ModalBody>
          </Modal>

          {/*add pay diff transaction*/}
          <Modal
            className="modal-primary modal-dialog-centered"
            isOpen={addNoteFraisModal}
            toggle={() => {
              setAddNoteFraisModal(false);
              restePayDiffForm();
              setPayDiffCreateLoading(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setAddNoteFraisModal(false);
                restePayDiffForm();
                setPayDiffCreateLoading(false);
              }}
            >
              Ajouter une transaction
            </ModalHeader>
            <form onSubmit={payDiff_handleSubmit(createTransactionDec)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="label">Libellé</Label>
                        <Input
                          id="label"
                          innerRef={labelRef}
                          {...label}
                          type="text"
                          className="form-primary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-primary ">
                          {<ReactSVG src={CalenderIconGreen} />}
                          {/* <img src={CalenderIconGreen} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Date</Label>
                        <Controller
                          control={payDiff_control}
                          name="date"
                          render={({ field }) => (
                            <DatePicker
                              onChange={(date: any) =>
                                field.onChange(payDiffTrans?.date)
                              }
                              selected={
                                payDiffTrans?.date
                                  ? new Date(payDiffTrans?.date)
                                  : null
                              }
                              className="form-control form-primary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              disabled
                            />
                          )}
                        />
                        <span className="icon icon-primary ">
                          {<ReactSVG src={CalenderIconGreen} />}
                          {/* <img src={CalenderIconGreen} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Catégorie</Label>
                        <Controller
                          name="category"
                          control={payDiff_control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={[...decCategs, ...encCategs]
                                .reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    ...(curr.sub_categories || []),
                                  ],
                                  [] as ISubCategory[]
                                )
                                .filter(
                                  (elt) =>
                                    !(
                                      [
                                        PayDiffKeys.IN26,
                                        PayDiffKeys.IN27,
                                        PayDiffKeys.OUT100,
                                        PayDiffKeys.OUT101,
                                      ] as unknown as string
                                    ).includes(elt.key)
                                )
                                .reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    { value: curr.id, label: curr.name },
                                  ],
                                  [] as { value: string; label: string }[]
                                )}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-primary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    {decCategs
                      .reduce(
                        (acc, curr) => [...acc, ...(curr.sub_categories || [])],
                        [] as ISubCategory[]
                      )
                      .filter(
                        (elt) =>
                          elt.key !== PayDiffKeys.OUT100 &&
                          elt.key !== PayDiffKeys.OUT101
                      )
                      .findIndex(
                        (elt) =>
                          elt.id === _category?.value &&
                          elt.key === CarbKeys.OUT028
                      ) > -1 && (
                      <Col md={12}>
                        <FormGroup className="form-icon icon-start">
                          <Label for="date">
                            Quel est le type de véhicule ?
                          </Label>
                          <Controller
                            name="carbVehType"
                            control={payDiff_control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={[
                                  {
                                    label: "Véhicule utilitaire",
                                    value: CarbVehTypes.Utility,
                                  },
                                  {
                                    label: "Véhicule de tourisme",
                                    value: CarbVehTypes.Tourisme,
                                  },
                                ]}
                                closeMenuOnSelect={true}
                                classNamePrefix="select"
                                className="custom-select form-primary"
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="amount">Montant</Label>
                        <Input
                          id="amount"
                          innerRef={amountRef}
                          {...amount}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          step={0.01}
                          className="form-primary"
                          onChange={() => {
                            setErrorMessage(null);
                            if (!getValues("sign")) {
                              payDiff_setValue("sign", "neg");
                            }
                          }}
                          // min={0}
                        />
                        <span className="icon icon-primary ">
                          {<ReactSVG src={CalenderIconGreen} />}
                          {/* <img src={CalenderIconGreen} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <div className="top-chois-form">
                        <Row>
                          <Col md={6} sm={12} className="left-label">
                            <Label className="label-primary">
                              Est-ce un décaissement ?
                            </Label>
                          </Col>
                          <Col md={6} sm={12}>
                            <div className="form-line">
                              <div className="check-box check-primary">
                                <input
                                  type="radio"
                                  id="decCheckBox1"
                                  name="radio-group"
                                  onClick={() =>
                                    payDiff_setValue("sign", "neg")
                                  }
                                  defaultChecked={true}
                                />
                                <label htmlFor="decCheckBox1">Oui</label>
                              </div>
                              <div className="check-box check-primary">
                                <input
                                  type="radio"
                                  id="decCheckBox2"
                                  name="radio-group"
                                  onClick={() =>
                                    payDiff_setValue("sign", "pos")
                                  }
                                />
                                <label htmlFor="decCheckBox2">Non</label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    {billSuggestions.length > 0 && (
                      <Col md={12}>
                        <FormGroup>
                          <span className="icon-invoice-download primary">
                            <FaFileInvoice /> Ajoutez une ou plusieurs factures{" "}
                            <strong
                              onClick={async () => {
                                try {
                                  if (
                                    !getValues("amount") ||
                                    getValues("amount") === 0
                                  ) {
                                    toast.dismiss();
                                    toast.warning(
                                      `Vous devez ajouter un montant valide .`,
                                      {
                                        position: "bottom-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                    return;
                                  }
                                  setBillSuggestModal(true);
                                } catch (error: any) {
                                  ErrorLogger(
                                    "updating a transaction's document",
                                    error
                                  );
                                }
                              }}
                            >
                              ici
                            </strong>
                          </span>
                        </FormGroup>
                      </Col>
                    )}

                    <Col md={12}>
                      <FilePicker
                        className="form-file file-primary "
                        onChange={handleDocumentsChange}
                        state={documents}
                        renderType={"array"}
                        fileStagedDelete={(file: { name: any }) =>
                          setDocuments((prevState: any) => {
                            return prevState.filter(
                              (elt: { name: any }) =>
                                elt.name !== (file as any).name
                            );
                          })
                        }
                        setUrl={setUrl}
                        setViewModal={setViewModal}
                        isMultiple={true}
                      />
                    </Col>
                  </Row>
                  {errorMessage?.type === "invalid_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                  {errorMessage?.type === "undexpected_issue" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddNoteFraisModal(false);
                    restePayDiffForm();
                  }}
                  type="button"
                  disabled={payDiffCreateLoading}
                >
                  Annuler
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    // payDiff_setValue("modalType", "negative");
                  }}
                  disabled={payDiffCreateLoading}
                >
                  {payDiffCreateLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Ajouter"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*add enc diff transaction*/}
          <Modal
            className="modal-primary modal-dialog-centered"
            isOpen={addEncModal}
            toggle={() => {
              setAddEncModal(false);
              restePayDiffForm();
              setPayDiffCreateLoading(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setAddEncModal(false);
                restePayDiffForm();
                setPayDiffCreateLoading(false);
              }}
            >
              Ajouter une transaction
            </ModalHeader>
            <form onSubmit={payDiff_handleSubmit(createTransactionEnc)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="label">Libellé</Label>
                        <Input
                          id="label"
                          innerRef={labelRef}
                          {...label}
                          type="text"
                          className="form-primary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-primary ">
                          {<ReactSVG src={CalenderIconGreen} />}
                          {/* <img src={CalenderIconGreen} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Date</Label>
                        <Controller
                          control={payDiff_control}
                          name="date"
                          render={({ field }) => (
                            <DatePicker
                              onChange={(date: any) =>
                                field.onChange(payDiffTrans?.date)
                              }
                              selected={
                                payDiffTrans?.date
                                  ? new Date(payDiffTrans?.date)
                                  : null
                              }
                              className="form-control form-primary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                              disabled
                            />
                          )}
                        />
                        <span className="icon icon-primary ">
                          {<ReactSVG src={CalenderIconGreen} />}
                          {/* <img src={CalenderIconGreen} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Catégorie</Label>
                        <Controller
                          name="category"
                          control={payDiff_control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={[...decCategs, ...encCategs]
                                .reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    ...(curr.sub_categories || []),
                                  ],
                                  [] as ISubCategory[]
                                )
                                .filter(
                                  (elt) =>
                                    !(
                                      [
                                        PayDiffKeys.IN26,
                                        PayDiffKeys.IN27,
                                        PayDiffKeys.OUT100,
                                        PayDiffKeys.OUT101,
                                      ] as unknown as string
                                    ).includes(elt.key)
                                )
                                .reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    { value: curr.id, label: curr.name },
                                  ],
                                  [] as { value: string; label: string }[]
                                )}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-primary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="amount">Montant</Label>
                        <Input
                          id="amount"
                          innerRef={amountRef}
                          {...amount}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          step={0.01}
                          className="form-primary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                          // min={0}
                        />
                        <span className="icon icon-primary ">
                          {<ReactSVG src={CalenderIconGreen} />}
                          {/* <img src={CalenderIconGreen} alt="icon" /> */}
                        </span>
                      </FormGroup>
                    </Col>
                    {billSuggestions.length > 0 && (
                      <Col md={12}>
                        <FormGroup>
                          <span className="icon-invoice-download primary">
                            <FaFileInvoice /> Ajoutez une ou plusieurs factures{" "}
                            <strong
                              onClick={async () => {
                                try {
                                  if (
                                    !getValues("amount") ||
                                    getValues("amount") === 0
                                  ) {
                                    toast.dismiss();
                                    toast.warning(
                                      `Vous devez ajouter un montant valide .`,
                                      {
                                        position: "bottom-right",
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                    return;
                                  }
                                  setBillSuggestModal(true);
                                } catch (error: any) {
                                  ErrorLogger(
                                    "updating a transaction's document",
                                    error
                                  );
                                }
                              }}
                            >
                              ici
                            </strong>
                          </span>
                        </FormGroup>
                      </Col>
                    )}
                    <Col md={12}>
                      <FilePicker
                        className="form-file file-primary "
                        onChange={handleDocumentsChange}
                        state={documents}
                        renderType={"array"}
                        fileStagedDelete={(file: { name: any }) =>
                          setDocuments((prevState: any) => {
                            return prevState.filter(
                              (elt: { name: any }) =>
                                elt.name !== (file as any).name
                            );
                          })
                        }
                        setUrl={setUrl}
                        setViewModal={setViewModal}
                        isMultiple={true}
                      />
                    </Col>
                  </Row>
                  {errorMessage?.type === "invalid_form" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                  {errorMessage?.type === "undexpected_issue" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddNoteFraisModal(false);
                    setAddEncModal(false);
                    restePayDiffForm();
                  }}
                  type="button"
                  disabled={payDiffCreateLoading}
                >
                  Annuler
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    // payDiff_setValue("modalType", "negative");
                  }}
                  disabled={payDiffCreateLoading}
                >
                  {payDiffCreateLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Ajouter"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*
         transaction documents
        */}
          <Modal
            className="modal-primary modal-dialog-centered "
            isOpen={documentsModal}
            toggle={() => {
              setDocumentsModal(false);
              setViewDocumentsTransaction(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setDocumentsModal(false);
                setViewDocumentsTransaction(null);
              }}
            >
              Justificatifs de la transaction {viewDocumentsTransaction?.label}
            </ModalHeader>

            <ModalBody>
              <div className="content-form-block">
                <DataTable
                  columns={documentsColumns}
                  data={viewDocumentsTransaction?.documents!}
                  noDataComponent={<p>Il n'y a aucun data à afficher</p>}
                  paginationComponent={(props) => {
                    const customProps = { ...props, color: "primary" };
                    return <BootyPagination {...customProps} />;
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          {/*
        tva modal 
        */}
          <Modal
            className="modal-secondary modal-dialog-centered modal-lg"
            isOpen={tvaModal}
            toggle={async () => {
              closeTVAModal();
            }}
          >
            <ModalHeader
              toggle={async () => {
                closeTVAModal();
              }}
            >
              Taux de TVA
            </ModalHeader>
            <ModalBody>
              <div className="content-form-block">
                <div className="tva-choice-upper">
                  <h5>
                    Veuillez choisir la tva adéquate (montant de{" "}
                    {singleTrans?.amount} €) :
                  </h5>
                  <Select
                    options={TVAS_OPTIONS}
                    closeMenuOnSelect={true}
                    classNamePrefix="select"
                    className="custom-select form-secondary"
                    onChange={(e) => {
                      setSingleTVAAmount(e);
                      if (e?.value === "mixte") {
                        setSingleTVAAmount(null);
                        setMixteTVASection(true);
                        return;
                      }
                      if (mixteTVASection && e?.value !== "mixte") {
                        setMixteTVASection(false);
                        closeRow();
                        setRows([]);
                        return;
                      }
                    }}
                    defaultValue={singleTVAAmount}
                  />
                </div>

                {mixteTVASection && (
                  <div className="table-fact table-tva table-responsive">
                    <form onSubmit={tva_handleSubmit(addTVARow)}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Montant</th>
                            <th>TVA</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows.map((elt, index) => (
                            <tr key={index}>
                              <td>{elt.tva_amount}</td>
                              <td>{elt.tva?.value}</td>
                              <td className="tva-action">
                                <button
                                  type="button"
                                  className="btn btn-blue"
                                  onClick={() => {
                                    resetRow(elt);
                                    deleteRow(elt.id);
                                    setAddNewRow(true);
                                  }}
                                >
                                  <IoMdCreate />
                                </button>
                                <button
                                  className="btn btn-red"
                                  type="button"
                                  onClick={() => {
                                    deleteRow(elt.id);
                                  }}
                                >
                                  <BsTrashFill />
                                </button>
                              </td>
                            </tr>
                          ))}

                          {/* add new row */}
                          {addNewRow && (
                            <tr>
                              <td>
                                <div className="input-device-left">
                                  <Input
                                    id="tva_amount"
                                    type="number"
                                    className="form-secondary small outline"
                                    onWheel={(e) => (e.target as any).blur()}
                                    innerRef={tva_amountRef}
                                    {...tva_amount}
                                    step={0.01}
                                  />
                                  <span className="devise">€</span>
                                </div>
                              </td>
                              <td>
                                <Controller
                                  name="tva"
                                  control={tva_control}
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      options={
                                        TVAS_OPTIONS.filter(
                                          (elt) => elt.value !== "mixte"
                                        ) as unknown as {
                                          value: number;
                                          label: string;
                                        }[]
                                      }
                                      closeMenuOnSelect={true}
                                      classNamePrefix="select"
                                      className="custom-select form-secondary outline small categ-select"
                                    />
                                  )}
                                />
                              </td>
                              <td className="tva-action">
                                <button
                                  className="btn btn-blue btn-save"
                                  type="submit"
                                >
                                  <SaveIconWhite />
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-red"
                                  onClick={() => closeRow()}
                                >
                                  <RiCloseCircleFill />
                                </button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </form>
                    {!addNewRow && (
                      <div className="action-add-row">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setAddNewRow(true)}
                        >
                          {<ReactSVG src={IconPlusYellow} />}
                          {/* <img src={IconPlusYellow} alt="icon" /> */}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                outline
                onClick={() => {
                  closeTVAModal();
                }}
                type="button"
                disabled={tvaUpdateLoading}
              >
                Annuler
              </Button>
              <Button
                color="secondary"
                onClick={async () => {
                  await cleanSplittedTransactionTVA(singleTrans?.id!);
                  if (rows.length === 0) {
                    await updateTransactionTVA(
                      singleTrans?.id!,
                      singleTrans?.amount!,
                      singleTVAAmount?.value! as unknown as number
                    );
                  } else {
                    await updateSplittedTransactionTVA(
                      singleTrans?.id!,
                      singleTrans?.amount!
                    );
                  }
                }}
                disabled={tvaUpdateLoading}
              >
                {tvaUpdateLoading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Valider"
                )}
              </Button>
            </ModalFooter>
          </Modal>

          <FileViewer
            url={url!}
            setUrl={setUrl}
            viewModal={viewModal}
            setViewModal={setViewModal}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionsAvalider;
