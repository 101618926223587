export default {
  development: {
    API_URL: "https://api.v2.noly-compta.fr:3500",
    APPLICATION_ID: "app_hwVOgau4A",
    BaseURL: "http://localhost:3000",
    APPLICATION_NAME: "Noly Compta",
    TRACKING_ID: "UA-241000198-1",
  },
  production: {
    API_URL: "https://api.v2.noly-compta.fr:3500",
    APPLICATION_ID: "app_hwVOgau4A",
    BaseURL: "https://app.noly-compta.fr",
    APPLICATION_NAME: "Noly Compta",
    TRACKING_ID: "UA-241000198-1",
  },
  test: {
    API_URL: "https://api.v2.noly-compta.fr:3500",
    APPLICATION_ID: "app_hwVOgau4A",
    BaseURL: "https://app.noly-compta.fr",
    APPLICATION_NAME: "Noly Compta",
    TRACKING_ID: "UA-241000198-1",
  },
};
